'''
Bowling Club : Leaderboard

@auther Jungho song (dev@threeword.com)
@since 2017.04.26
'''
class exports.LeaderBoard extends BaseLayer

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'ranks'
			backgroundColor: 'white'

		@title = options.title ?= ''
		@key = options.key ?= ''
		@keys = options.keys ?= []
		@orderBies = options.orderBies ?= []
		@data = null

	# 생성
	create: (data) ->
		@setAppBar appbar = new AppBar
			width: @width
			isFullscreen: @isFullscreen
			title: @title
			subTitle: 'Leaderboard'
			backgroundColor: 'white'

		actionClose = appbar.setHome R.drawable.actionClose
		actionClose.onClick @onClose

		# 에버리지인 경우
		if @title == 'Average'
			actionGames = appbar.addOptionMenuLabel '전체'

			options = new Options 
				size: @size
				contentInset: right: 18, top: appbar.height
				parent: @
			options.placeBehind(appbar)

			# 값 변경
			options.onChange 'value', (value) => 
				actionGames.html = if value == 0 then '전체' else "최근 #{value}게임"
			# 완료
			options.onDone (value) => @_reqGames value
			
			# 보이기/감추기 토글
			actionGames.onClick => options.toggle()

		@data = data
		console.debug @data

		# 정렬
		sortedDashboards = _.orderBy _.values(data), @keys, @orderBies
		# 게임기록 없는 사용자 맨밑으로
		sortedDashboards = _.orderBy sortedDashboards, (o) -> return o.games == 0

		wrap = new Layer y: appbar.maxY, size: @size, backgroundColor: ''

		wrap.top3 = new Layer
			width: @width, height: 430
			shadowY: 1, shadowBlur: 1, shadowColor: 'rgba(164,164,164,.5)'
			backgroundColor: 'white'
			parent: wrap
	
		y = wrap.top3.maxY
		@members = []
		for dashboad, i in sortedDashboards
			if i == 0 or i == 1 or i == 2
				top = new Top
					y: 42
					data: dashboad
					key: @key
					ranking: i
					parent: wrap.top3
				if i == 0 then top.x = Align.center
				else if i == 1 then top.x = 57
				else if i == 2 then top.x = Align.right(-57)

				@members.push top
			else
				etc = new Etc
					y: y 
					width: wrap.width
					data: dashboad
					key: @key
					ranking: i
					parent: wrap
				y += etc.height

				@members.push etc

		wrap.top3.bringToFront()

		wrap.height = wrap.contentFrame().height + 50
		wrap.y = appbar.maxY
		wrap.y -= 40 if @isFullscreen

		@setContent(wrap)

	# 닫기
	onClose: =>
		BowlingClub.get().fc.showPrevious()
		BowlingClub.get().fc.once Events.TransitionStop, => @destroy()

	# 데이터 바인딩
	bind: ->
		if @members and !_.isEmpty @members
			for member, i in @members
				do (member) -> Utils.delay .05 * i, -> member.create()


	# 요청 : 게임 
	_reqGames: (value) ->
		# console.debug value
		# console.debug @data

 		# 전체
		if value == 0 then @setData(@data)
		# 최신 N게임
		else
			Firebase.get().showLoading()
			data = _.cloneDeep @data
			count = 0
			len = _.size data
			for k, v of data
				do (k) =>
					Firebase.get().onceGetGamesLast(k, parseInt(value))
					.then (snapshot) => 
						unless _.isNull(snapshot.val())
							games = snapshot.val()
							total = _.chain(games)
									.values()
									.sumBy(Dto.game.score)
									.value()

							average = Utils.roundWhole total / _.size(games)

							# console.log total
							# console.log average
							# console.log total / _.size(games)

							data["#{k}"].average.current = average
							
						# console.log "#{++count}/#{len}"

						# 모두 로드
						if ++count == len
							@setData(data)
							Firebase.get().dismissLoading()
							
					.catch (error) -> alert error

	setData: (data) ->
		# 정렬
		sortedDashboards = _.orderBy _.values(data), @keys, @orderBies
		# 게임기록 없는 사용자 맨밑으로
		sortedDashboards = _.orderBy sortedDashboards, (o) -> return o.games == 0

		if @members and !_.isEmpty @members
			for member, i in @members
				member.bind sortedDashboards[i]



# 옵션 메뉴
class Options extends Layer
	Events.Done = 'done'

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			visible: false
			backgroundColor: ''

		@isShow = false
		@contentInset = options.contentInset
		@value = options.value ?= 0

		@dim = new Layer
			size: @size
			opacity: 0
			backgroundColor: 'rgba(0,0,0,.3)'
			parent: @
		
		@slider = new Layer
			x: Align.right(-@contentInset.right), y: Align.top(@contentInset.top)
			width: 80, height: 400
			style: borderRadius: '0px 0px 40px 40px'
			opacity: 1
			backgroundColor: 'white'
			parent: @
		@slider.states = 
			show: y: Align.top(@contentInset.top)
			dismiss: y: @contentInset.top - 400
			animationOptions: time: .15, curve: "spring(500,40,0)"

		@slider.rail = new Layer
			x: Align.center, y: 50
			width: 14, height: 250
			borderRadius: 7, borderColor: 'rgba(234,234,234,.5)', borderWidth: 4
			backgroundColor: '#D8D8D8'
			parent: @slider

		@slider.knob = new Layer
			x: Align.center, midY: @slider.rail.y
			size: 60
			borderRadius: 30, borderColor: '#A3A3A3', borderWidth: 4
			backgroundColor: 'white'
			parent: @slider

		@slider.knob.value = new TextLayer
			point: Align.center
			width: @slider.knob.width
			html: 'All'
			fontSize: 20, fontWeight: 500
			letterSpacing: -0.53
			textAlign: 'center'
			color: '#4A4A4A'
			parent: @slider.knob

		@slider.done = new Layer
			x: Align.center, y: Align.bottom(-10)
			size: 50
			backgroundColor: ''
			parent: @slider

		@slider.done.text = new TextLayer
			x: Align.center, y: 5
			html: 'OK'
			fontSize: 20, fontWeight: 500
			letterSpacing: -0.53
			color: '#4A4A4A'
			parent: @slider.done

		@slider.knob.draggable.enable = true
		@slider.knob.draggable.horizontal = false
		@slider.knob.draggable.overdrag = false
		@slider.knob.draggable.bounce = false
		@slider.knob.draggable.constraints = 
			x: 0, y: @slider.rail.y - (@slider.knob.height / 2)
			width: @slider.rail.width, height: @slider.rail.height + @slider.knob.height

		# 액션 ------------------------------------------------------------------- /

		@slider.knob.onMove =>
			@value = parseInt(Utils.modulate @slider.knob.y, [20, 270], [0, 30], true)
			@slider.knob.value.html = if @value == 0 then 'All' else @value
			@emit 'change:value', @value, @

		@slider.onClick => console.debug 'block'
		@slider.done.onClick =>
			@emit Events.Done, @value, @
			@dismiss();
		@dim.onClick => @dismiss()

	toggle: -> if @isShow then @dismiss() else @show()

	show: ->
		@visible = true
		@dim.off Events.AnimationEnd
		@dim.animateStop()
		@dim.animate opacity: 1

		@slider.animateStop()
		@slider.animate 'show'

		@isShow = true

	dismiss: ->
		@slider.animateStop()
		@slider.animate 'dismiss'

		@dim.animateStop()
		@dim.animate opacity: 0
		@dim.once Events.AnimationEnd, => @visible = false if @visible

		@isShow = false

	onDone: (cb) -> @on Events.Done, cb

				
'''
Bowling Club : Leaderboard : Top 3

@auther Jungho song (dev@threeword.com)
@since 2017.04.26
'''
class Top extends Layer
	COLOR = ['#D86060', '#60C7D8', '#60D871']
	SIZE_PHOTO = [228, 150, 150]
	SIZE_RANKING = [50, 40, 40]
	SIZE_RANKING_FONT = [35, 25, 25]

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			height: 364
			opacity: 0
			backgroundColor: ''

		@data = options.data ?= null
		@key = options.key ?= null
		@ranking = options.ranking ?= 0

		@width = SIZE_PHOTO[@ranking]

	# 생성
	create: ->
		user = _.get @data, 'user'
		value = _.get(@data, @key)

		@photo = new Photo
			x: Align.center, y: if @ranking != 0 then 78 else 0
			size: @width
			borderRadius: 228, borderWidth: 4, borderColor: 'white'
			shadowY: 2, shadowBlur: 4, shadowColor: 'rgba(0,0,0,.5)'
			parent: @
		# @photo.setImage user.photoThumbUrl unless _.isEmpty user.photoThumbUrl
		if user.photoThumbUrl?
			Firebase.get().downloadThumbURL(@data.uid)
			.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)
		
		@rank = new Layer
			x: Align.center, midY: @photo.maxY
			size: SIZE_RANKING[@ranking]
			borderRadius: 50, borderWidth: 2, borderColor: 'white'
			shadowY: 2, shadowBlur: 4, shadowColor: 'rgba(0,0,0,.5)'
			backgroundColor: COLOR[@ranking]
			parent: @
		@rank.value = new TextLayer
			point: Align.center
			html: @ranking + 1
			fontSize: SIZE_RANKING_FONT[@ranking], fontWeight: 500
			color: 'white'
			parent: @rank

		@displayName = new TextLayer
			x: Align.center, y: @rank.maxY + 23
			html: user.displayName
			fontSize: 24, fontWeight: 500
			textAlign: 'center'
			color: R.color.grey_c0c0c0
			parent: @

		@score = new TextLayer
			x: Align.center, y: @displayName.maxY
			html: value
			fontSize: 50, fontWeight: 500
			textAlign: 'center'
			color: R.color.grey_787878
			parent: @

		@width = @contentFrame().width

		# 애니메이션
		@animate opacity: 1, options: time: .15

		@displayName.onChange 'html', =>
			Util.text.autoSize @displayName
			@displayName.centerX()
			@width = @contentFrame().width

		@score.onChange 'html', =>
			Util.text.autoSize @score
			@score.centerX()
			@width = @contentFrame().width

	# 데이터 바인딩
	bind: (data) ->
		@data = data

		user = _.get @data, 'user'
		value = _.get(@data, @key)

		# unless _.isEmpty user.photoThumbUrl then @photo.setImage user.photoThumbUrl else @photo.clear()
		if user.photoThumbUrl?
			Firebase.get().downloadThumbURL(@data.uid)
			.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)
		else @photo.clear()


		@displayName.html = user.displayName
		@score.html = value

'''
Bowling Club : Leaderboard : Top 4 -

@auther Jungho song (dev@threeword.com)
@since 2017.04.26
'''
class Etc extends Layer
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			height: 120
			opacity: 0
			backgroundColor: 'white'

		@data = options.data ?= null
		@key = options.key ?= null
		@ranking = options.ranking ?= 0

	# 생성
	create: ->
		user = _.get @data, 'user'
		value = _.get(@data, @key)

		@rank = new TextLayer
			y: Align.center
			width: 70
			html: @ranking + 1
			fontSize: 30
			textAlign: 'right'
			color: R.color.grey_bcbcbc
			parent: @

		@photo = new Photo
			x: @rank.maxX + 31, y: Align.center
			size: 70
			borderRadius: 35, borderColor: R.color.grey_979797, borderWidth: 1
			parent: @
		# @photo.setImage user.photoThumbUrl unless _.isEmpty user.photoThumbUrl
		if user.photoThumbUrl?
			Firebase.get().downloadThumbURL(@data.uid)
			.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)
			
		@displayName = new TextLayer
			x: @photo.maxX + 11, y: @photo.y + 4
			html: user.displayName
			fontSize: 35, fontWeight: 400
			letterSpacing: -1.2
			truncate: true
			color: R.color.grey_4a4a4a
			parent: @
		@displayName.width = @width - @displayName.x
		
		@email = new TextLayer
			x: @photo.maxX + 12, y: @displayName.maxY - 4
			html: user.email
			fontSize: 20, fontWeight: 400
			color: R.color.grey_4a4a4a
			parent: @
		@email.width = @width - @email.x

		@value = new TextLayer
			x: Align.right(-61), y: Align.center
			html: value
			fontSize: 50
			textAlign: 'right'
			color: R.color.grey_787878
			parent: @

		line = new Layer
			x: @photo.x, y: Align.bottom
			width: @width - @photo.x, height: 1
			backgroundColor: R.color.grey_d8d8d8
			parent: @

		# 애니메이션
		@animate opacity: 1, options: time: .15

		@displayName.onChange 'html', =>
			Util.text.autoSize @displayName
			@displayName.x = @photo.maxX + 11
			@displayName.width = @width - @displayName.x
		@email.onChange 'html', =>
			Util.text.autoSize @email
			@email.x = @photo.maxX + 12
			@email.width = @width - @email.x
			
		@value.onChange 'html', =>
			Util.text.autoSize @value
			@value.x = Align.right(-61)

	# 데이터 바인딩
	bind: (data) ->
		@data = data

		user = _.get @data, 'user'
		value = _.get @data, @key

		# unless _.isEmpty user.photoThumbUrl then @photo.setImage user.photoThumbUrl else @photo.clear()
		if user.photoThumbUrl?
			Firebase.get().downloadThumbURL(@data.uid)
			.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)
		else @photo.clear()
		@displayName.html = user.displayName
		@email.html = user.email
		@value.html = value