'''
Bowling Club : Login

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Login extends BaseLayer
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'login'
			backgroundColor: 'black'

	create: ->
		super
		
		wrap = new Layer name: '.', width: 570, backgroundColor: ''
		wrap.line = new Layer
			x: 10, y: 20
			width: 60, height: 5
			backgroundColor: R.color.accentColor
			parent: wrap
			
		wrap.appLabel = new TextLayer
			x: wrap.line.maxX + 31
			width: 240, height: 46
			html: 'Bowling Club'
			fontSize: 40
			fontWeight: 500
			letterSpacing: -0.8
			backgroundColor: ''
			color: 'white'
			parent: wrap
		
		wrap.loginLabel = new TextLayer
			y: wrap.appLabel.maxY + 31
			html: 'Login'
			fontSize: 120
			fontWeight: 500
			letterSpacing: -2.2
			color: 'white'
			parent: wrap
		
		wrap.bar = new Layer
			x: 95, y: wrap.loginLabel.maxY + 65
			width: 280, height: 10
			backgroundColor: 'white'
			parent: wrap
				
		wrap.email = new Input
			y: wrap.bar.maxY + 137
			icon: R.drawable.email
			type: "email"
			placeHolder: "Email", placeHolderFocus: "Email"
			pattern: ""
			parent: wrap
		
		wrap.password = new Input
			y: wrap.email.maxY + 48
			icon: R.drawable.lock
			type: "password"
			placeHolder: "Password", placeHolderFocus: "Password"
			pattern: ""
			parent: wrap

		wrap.remember = new Checkbox
			x: 6, y: wrap.password.maxY + 45
			width: 250, height: 40
			fontSize: 35, 
			borderColor: 'white'
			checkedColor: 'white'
			drawablePadding: 28
			text: 'Remember Me'
			color: "white"
			parent: wrap
			
		wrap.login = new Button
			y: wrap.remember.maxY + 62
			width: 570, height: 100
			borderColor: R.color.accentColor, borderWidth: 5
			pressed: R.color.accentColor
			backgroundColor: ''
			parent: wrap
		wrap.login.bar = new Layer
			x: 35, y: Align.center
			width: 270, height: 10
			backgroundColor: 'white'
			parent: wrap.login
		wrap.login.label = new TextLayer
			x: Align.right(-30), y: Align.center
			width: 200, height: 40
			html: 'LOGIN NOW'
			fontSize: 35
			fontWeight: 500
			textAlign: 'center'
			color: 'white'
			backgroundColor: ''
			parent: wrap.login

		wrap.signup = new TextLayer
			y: wrap.login.maxY + 98
			width: 170, height: 31
			html: "SIGNUP NOW"
			fontSize: 27, fontWeight: 500
			color: R.color.grey_c0c0c0
			parent: wrap
			
		wrap.forgot = new TextLayer
			x: Align.right, y: wrap.signup.y
			width: 210, height: 27
			html: "Forgot password?"
			fontSize: 24, fontWeight: 500
			textAlign: 'right'
			color: R.color.grey_c0c0c0
			parent: wrap

		@remember = wrap.remember
	
		wrap.height = wrap.contentFrame().height + 30
		wrap.x = (@width - wrap.width) / 2
		wrap.y = @height - wrap.height - 86
		wrap.y -= 40 if @isFullscreen

		@setContent(wrap)

		# Action ------------------------------------------------------------------------ //

		account = Preference.get().getAccount()
		if account and !_.isEmpty account
			wrap.remember.checked = true
			wrap.email.value = account.email
			wrap.password.value = account.password

			# @_reqLogin(account.email, account.password)

		# login
		wrap.login.onClick =>
			email = wrap.email.value;
			password = wrap.password.value;

			# email and password valid
			if !_.isEmpty(email) and !_.isEmpty(password)
				# email valid
				unless Util.valid.email(email) then alert '이메일이 올바르지 않아요.'
				else @_reqLogin(email, password);
					

			else alert '입력 정보를 확인하세요.'

		# singup
		wrap.signup.onClick =>
			signup = new SignUp
			signup.create()
			BowlingClub.get().fc.showNext signup, scroll: false
			BowlingClub.get().fc.onTransitionStop => @destroy()

		# forgot password
		wrap.forgot.onClick =>
			email = window.prompt '비밀번호 찾기\n\n입력하신 이메일로 비밀번호 재설정 이메일을 보내드려요.', ''
			
			# accept
			unless email == null 
				# valid email
				unless Util.valid.email(email) then alert '이메일이 올바르지 않아요.'
				# send reset password email
				else 
					Firebase.get().sendPasswordResetEmail(email)
					.then () => 
						alert '이메일을 보내드렸어요.';
					, (error) -> alert(error)

	# Log in
	_reqLogin: (email, password) ->
		Firebase.get().showLoading()
		Firebase.get().signInWithEmailAndPassword(email, password)
		.then (snapshot) =>
			# svae account : is Remember Me
			if @remember.checked then Preference.get().setAccount email, password 
			# clear account
			else Preference.get().clearAccount()

			Firebase.get().dismissLoading()
			clubIds = Firebase.get().clubIds
			# 클럽있는 경우
			if clubIds and !_.isEmpty clubIds
				#
				home = new Home()
				home.create()
				BowlingClub.get().fc.once Events.TransitionStop, => home.bind(); @destroy()
				BowlingClub.get().fc.showNext home, scroll: false
			# 클럽없는 경우
			else 						
				clubs = new Clubs()
				clubs.create()
				BowlingClub.get().fc.showNext clubs, scroll: false
				BowlingClub.get().fc.once Events.TransitionStop, => @destroy()

		, (error) -> alert(error)


		# debug
		# if Utils.isLocalUrl(document.URL)
		# 	wrap.email.value = 'ho@test.com'
		# 	wrap.password.value = '123456'
		# else
		# 	wrap.email.value = 'ho3@test.com'
		# 	wrap.password.value = '123456'