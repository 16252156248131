'''
Bowling Club : Profile

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Profile extends BaseLayer
	
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'profile'
			backgroundColor: 'white'

		@userId = options.userId ?= Firebase.get().user.uid
		@userData = options.userData ?= Firebase.get().userData

		@isMy = @userId == Firebase.get().user.uid

	# 생성
	create: ->
		@setAppBar appbar = new AppBar
			width: @width
			isFullscreen: @isFullscreen

		actionBack = appbar.setHome R.drawable.actionBack_w
		actionAdd = appbar.addOptionMenu R.drawable.actionAdd_w if @isMy
		appbar.statusBg.opacity = 0
			
		@setAppBar @appbarB = new AppBar
			width: @width
			isFullscreen: @isFullscreen
			title: ''
			
		actionBack = @appbarB.setHome R.drawable.actionBack
		actionAdd = @appbarB.addOptionMenu R.drawable.actionAdd if @isMy
		
		actionBack.onClick @onBack
		actionAdd.onClick @onAdd if @isMy
		
		@appbarB.opacity = 0
		
		wrap = new Layer size: @size, backgroundColor: ''
		
		# Profile
		@photo = new Preview
			size: @size
			originX: 0.5, originY: 0
			saturate: 0
			backgroundColor: 'lightgrey'
			parent: wrap
		@overlay = new Layer
			size: @size
			style: background: "-webkit-linear-gradient(bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 90%)"
			opacity: 1
			backgroundColor: ''
			parent: wrap
		
		@content.content.clip = false
		@content.content.onChange 'y', =>
			y = @content.content.y
			s = Utils.modulate y, [0, 200], [1, 1.4], true
			a = Utils.modulate y, [0, -500], [1, 0], true
			
			if y > 0
				@photo.y = @content.content.y * -1
				@photo.opacity = 1
				@appbarB.opacity = 0
			else 
				@photo.opacity = a
				@appbarB.opacity = 1 - a;
				
			if a == 0 then @appbarB.backgroundColor = 'white'
			else @appbarB.backgroundColor = ''
				
			@photo.scale = s
		
		# 이름
		@displayName = new TextLayer
			x: 30, y: 780
			html: 'Ho'
			fontSize: 70, fontWeight: 500
			letterSpacing: -2.9
			color: R.color.grey_696969
			parent: wrap
		@displayName.width += 5
		
		# 버튼 : 이름수정
		@displayNameEdit = new Layer _.extend R.drawable.edit,
			x: @displayName.maxX + 21, maxY: @displayName.maxY - 21
			visible: @isMy
			parent: wrap
			
		# 이메일
		@email = new TextLayer
			x: @displayName.x + 5, y: @displayName.maxY - 9
			width: @width
			fontSize: 40, fontWeight: 400
			letterSpacing: -2.1
			parent: wrap
		
		# 사진변경
		@camera = new InputPhoto
			x: Align.right(-27), maxY: @email.maxY
			size: 75
			borderRadius: 40, borderColor: R.color.grey_979797, borderWidth: 1
			backgroundColor: 'rgba(255,255,255,.4)'
			# preview: @photo
			visible: @isMy
			parent: wrap
		@camera.icon = new Layer _.extend R.drawable.camera_r,
			point: Align.center
			parent: @camera
			
		# 클럽정보
		@clubs = new ProfileClubs
			x: @displayName.x + 10, y: @email.maxY + 31
			isEdit: @isMy
			parent: wrap
		
		# 그래프
		@graph = new Graph
			y: @clubs.maxY + 81
			width: @width
			parent: wrap
		
		# 게임정보
		@games = new Layer
			y: @graph.maxY + 60
			width: @width
			backgroundColor: ''
			parent: wrap

		#
		wrap.height = wrap.contentFrame().height + 30
		@content.contentInset = { top: 0 }

		@setContent(wrap)

		# Action ----------------------------------------------------------- /

		@displayName.onChange 'html', =>
			Util.text.autoSize @displayName
			@displayName.width += 10
			@displayNameEdit.x = @displayName.maxX + 21

		@clubs.onChange 'size', =>
			@graph.y = @clubs.maxY + 81
			@games.y = @graph.maxY + 60
			wrap.height = wrap.contentFrame().height + 30

		@games.onChange 'height', =>
			wrap.height = wrap.contentFrame().height + 30

		# 이름변경
		@displayNameEdit.onClick =>
			changeName = prompt '이름변경', @displayName.html
			@_reqUpdateDisplayName changeName unless _.isEmpty(changeName)

		# 사진변경 : 파일로드
		_this = @
		@camera.onPreview (canvas, dataURI, inputPhoto, file, result) ->
			EXIF.getData file, -> 
				_this.photo.setImage dataURI, file, EXIF.getAllTags(this), result
				_this._reqUpdatePhoto _this.photo

		# 클릭 : 클럽추가 
		@clubs.add.onClick => @onClubs()

		#
		@bindUser()
		@bindClubs()
			
	# 콜백 : 뒤로가기
	onBack: =>
		BowlingClub.get().fc.showPrevious()
		BowlingClub.get().fc.once Events.TransitionStop, => @destroy()
		
	# 콜백 : 게임추가
	onAdd: =>
		score = new Score
		score.create()
		BowlingClub.get().fc.showOverlayBottom score, scroll: false
		BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
			BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
				@_reqGames() if b is this and direction == 'back'
					
	# 콜백 : 클럽추가
	onClubs: =>
		clubs = new Clubs isFromMy: true
		clubs.create()
		BowlingClub.get().fc.showNext clubs, scroll: false
		BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
			BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
				@_reqUserAndDashboard() if b is this and direction == 'back'

	# 콜백 : 게임수정
	onEdit: (item) =>
		score = new Score
		score.create(item.data)
		BowlingClub.get().fc.showOverlayBottom score, scroll: false
		BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
			BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
				@_reqGames() if b is this and direction == 'back'
					
	# 데이터 바인딩
	bind: -> @_reqGames()
		
	# 데이터 바인딩 : 클럽 
	bindClubs: -> @clubs.bind(@userData)

	# 데이터 바인딩 : 프로필
	bindUser: (snapshot) ->
		if snapshot
			Firebase.get().userData = snapshot.val()
			@userData = Firebase.get().userData

		user = @userData

		# display name
		@displayName.html = user.displayName
		# email
		@email.html = user.email
		# photo
		# @photo.setImage user.photoUrl unless _.isEmpty(user.photoUrl)
		if user.photoThumbUrl?
			Firebase.get().downloadThumbURL(@userId)
			.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)

		# appbar title
		@appbarB.title = user.displayName

		#
		Firebase.get().dismissLoading()

	# 데이터 바인딩 : 게임
	bindGame: (snapshot) ->
		
		games = snapshot.val()
		console.debug games
		# console.debug snapshot.numChildren()

		# 게임
		value.gameId = key for key, value of games
		results = _.chain(games)
				.orderBy 'updateAt', 'desc'
				.groupBy (value) -> moment(value.updateAt).format('YYYY년 MM월 DD일 (dddd)')
				.toPairs()
				.orderBy [(value) -> value[0]], ['desc']
				.value()
#		console.debug results

		child.destroy() for child in @games.children

		# 최근 10일만 보여줌
		limits = _.take results, Firebase.get().configuration.profile.limit.days

		y = 0;
		for result, i in limits
			date = result[0]
			games = result[1]
			header = new GameHeader
				y: y
				width: @width
				isFirst: i == 0
				value: result
				parent: @games

			y += header.height

			size = games.length
			for game, j in games
				item = new Game
					y: y
					width: @width
					isEdit: @isMy
					todayGameCount: size - j
					data: game
					parent: @games

				y += item.height

				# 수정
				item.onEdit (_item) => @onEdit _item

		@games.height = y

		# sticky header
		@content.content.off "change:y", @stickyHeaderCallback if @stickyHeaderCallback
		@stickyHeaderCallback = @_enableStickyHeader @content, @appbarB.maxY - @games.y, @games, '.stickyHeader'

		#
		@graph.bind results

	# get user
	_reqUserAndDashboard: ->
		Firebase.get().onceGetUserAndDashboard(@userId)
		.then (snapshot) => 
			@bindUser()
			@bindClubs()
		.catch (error) -> alert error

	# 업데이트 : 프로필 : 이름
	_reqUpdateDisplayName: (displayName) ->
		Firebase.get().showLoading()
		Firebase.get().updateUser(displayName, undefined)
		.then (snapshot) => @bindUser(snapshot)
		.catch (error) -> alert error

	# 업로드 : 프로필 : 사진
	_reqUpdatePhoto: (photoLayer) ->
		Firebase.get().showLoading()
		Utils.delay .5, =>
			photoLayer?.getImage (file, blob, exif) =>
				Firebase.get().uploadProfilePhoto(file, blob, exif)
				.then (snapshot) => @bindUser(snapshot)
				.catch (error) -> alert error

	# 게임로드
	_reqGames: (start, end) ->
		Firebase.get().onceGetGames(@userId, start, end)
#		Firebase.get().onceGetGamesYear(@userId)
		.then (snapshot) => @bindGame(snapshot)
		.catch (error) -> alert error

	# 스티키해더
	_enableStickyHeader: (scrollComponent, fixedTopPos = 0, parent = content, headerName = ".stickyHeader") ->

		_stickyHeaders = []
		_dataSH = []

		_stickyHeaders = parent.childrenWithName(headerName)

		if _stickyHeaders.length > 0
			for layer, i in _stickyHeaders
				layer.bringToFront()
				_dataSH.push(layer.y) 
				
		# 이벤트
		scrollComponent.content.onChange "y", stickyHeaderCallback = ->
			if _stickyHeaders.length > 0
				for layer, i in _stickyHeaders

					layer.y  =  _dataSH[i]
					_thisY   =  _dataSH[i] - scrollComponent.scrollY
					_fixedY  =  _dataSH[i] + -_thisY + fixedTopPos

					if i is 0
						if _thisY <= fixedTopPos
							layer.y = _fixedY

					if i > 0
						_prevStickyPosition = _dataSH[i] - _stickyHeaders[i-1].height
						_prevMaxY = _stickyHeaders[i-1].height + fixedTopPos

						# Transition
						if _thisY < _prevMaxY
							_stickyHeaders[i-1].y = _prevStickyPosition

						# New Sticky
						if _thisY <= fixedTopPos
							layer.y = _fixedY

		return stickyHeaderCallback
