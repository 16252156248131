'''
Bowling Club : Score

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Score extends BaseLayer
	@define 'data', @simpleProperty('data', null)
	@define 'isEdit', @simpleProperty('isEdit', false)
	@define 'datetime', @simpleProperty('datetime', null)
	
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'score'
			backgroundColor: 'white'

	# 뷰생성
	create: (data) ->
		@data = data
		@isEdit = data?
		
		# appbar
		@setAppBar appbar = new AppBar
			width: @width
			isFullscreen: @isFullscreen
		actionClose = appbar.setHome R.drawable.actionClose

		btnLabel = ''
		# 등록
		unless @isEdit 
			# TODO 출석체크
			# actionAttendance = appbar.addOptionMenuLabel '클럽 출첵'
			# actionAttendance.onClick ->
			btnLabel = '점수등록'
		# 수정
		else
			actionDelete = appbar.addOptionMenuLabel '삭제'
			actionDelete.onClick => 
				isDelete = confirm '정말 삭제하세요?'
				@onDelete() unless isDelete == null
			btnLabel = '점수수정'
	
		wrap = new Layer y: appbar.maxY, size: @size, backgroundColor: ''
		
		# 날짜 : 보여주기
		@date = new TextLayer
			x: 35
			html: @datetime = moment().format('YYYY년 MM월 DD일')
			fontSize: 50, fontWeight: 300
			letterSpacing: -1.3
			color: R.color.grey_4a4a4a
			parent: wrap

		# 날짜 : 선택
		@dateField = new InputField
			frame: @date.frame
			value: @datetime = moment().format('YYYY년 MM월 DD일')
			type: 'date', indent: 0
			fontSize: 50, fontWeight: 300, fontFamily: 'Kakao'
			autoCapitalize: false, autoComplete: false, autoCorrect: false
			textAlign: 'left', letterSpacing: "-1.3px"
			color: R.color.grey_4a4a4a
			backgroundColor: ''
			opacity: 0
			parent: wrap
		
		# 사진
		@photo = new Layer
			x: Align.center, y: @date.maxY + 132
			width: 680, height: 482
			backgroundColor: ''
			parent: wrap
		
		# 사진 : '결과 사진 첨부''
		@photo.text = new TextLayer
			point: Align.center
			html: '결과 사진 첨부'
			fontSize: 50, fontWeight: 500
			letterSpacing: -1.3
			color: R.color.grey_4a4a4a
			parent: @photo
		
		# 사진 : 크롭	영역
		@photo.crop = new Layer
			point: Align.center
			size: @photo.size
			borderColor: R.color.grey_979797, borderWidth: 2
			backgroundColor: ''
			style: 'display': 'inline-block'
			parent: @photo
			ignoreEvents: false
		@photo.crop._element.id = 'cropimage'
		
		# 사진 : 크롭 코어
		@croppie = new Croppie document.getElementById('cropimage'),
				enableOrientation: true
				mouseWheelZoom: true
				showZoomer: false
				viewport: { width: 680, height: 122 }
		
		@photo.chooser = new InputPhoto
			x: Align.center, midY: @photo.height
			size: 120
			borderRadius: 60, borderColor: R.color.grey_979797, borderWidth: 2
			shadowBlur: 10
			backgroundColor: 'rgba(255,255,255, 1)'
			parent: @photo
		@photo.icon = new Layer _.extend R.drawable.camera_r,
			point: Align.center
			parent: @photo.chooser
		
		@photo.rotateLeft = new Layer
			x: Align.center(-120), midY: @photo.height
			size: 80
			borderRadius: 40, borderColor: R.color.grey_979797, borderWidth: 2
			shadowBlur: 10
			opacity: .5
			backgroundColor: 'rgba(255,255,255, 1)'
			parent: @photo
		@photo.rotateLeft.icon = new Layer _.extend R.drawable.rotateLeft,
			point: Align.center
			opacity: .8
			parent: @photo.rotateLeft
		@photo.rotateRight = new Layer
			x: Align.center(120), midY: @photo.height
			size: 80
			borderRadius: 40, borderColor: R.color.grey_979797, borderWidth: 2
			shadowBlur: 10
			opacity: .5
			backgroundColor: 'rgba(255,255,255, 1)'
			parent: @photo
		@photo.rotateRight.icon = new Layer _.extend R.drawable.rotateRight,
			point: Align.center
			opacity: .8
			parent: @photo.rotateRight
		
		# 점수입력 : 컨테이너
		@inputScore = new Layer
			x: Align.right(-41), y: @height - 450 - 29
			width: @width - 41, height: 251
			backgroundColor: ''
			parent: wrap
		
		# 버튼 : 점수등록 
		@inputScore.btn = new TextLayer
			x: Align.right, y: Align.bottom
			html: btnLabel
			fontSize: 60, fontWeight: 500
			letterSpacing: -1.6
			color: R.color.grey_4a4a4a
			parent: @inputScore
		
		# 입력필드 : 점수입력 
		@inputScore.score = new InputField
			maxX: @inputScore.btn.x - 66
			width: 400, height: 251
			type: 'number-only', indent: 0
			fontSize: 220, fontWeight: 400
			placeHolder: '0', placeHolderFocus: '0', placeHolderColor: R.color.grey_e1e1e1
			autoCapitalize: false, autoComplete: false, autoCorrect: false
			textAlign: 'right', letterSpacing: '-10px'#'-13.5px'
			maxLength: 3
			color: R.color.grey_1f1f1f
			backgroundColor: ''
			parent: @inputScore
		
		# 라인
		@inputScore.line = new Layer
			x: @inputScore.score.x, maxY: @inputScore.score.maxY
			width: @inputScore.score.width, height: 10
			backgroundColor: R.color.grey_626262
			parent: @inputScore
		

		wrap.height = wrap.contentFrame().height + 30
		wrap.y = appbar.maxY
		wrap.y -= 40 if @isFullscreen
				
		@setContent(wrap)

		# Action -----------------------------------------------------------------/

		actionClose.onClick => @onClose()
		
		# date change
		@dateField.onInput (value) =>
			@date.html = @datetime = moment(value, "YYYY-MM-DD").format('YYYY년 MM월 DD일')
			Util.text.autoSize @date
			@dateField.frame = @date.frame

		# photo rotaion left
		@photo.rotateLeft.onClick => @croppie.rotate -90 if @opacity == 1
		# photo rotaion right
		@photo.rotateRight.onClick => @croppie.rotate 90 if @opacity == 1
		# photo choosen

		@photo.chooser.onLoad (result) =>
			@photo.rotateLeft.opacity = 1
			@photo.rotateRight.opacity = 1
			@croppie.bind url: result

			@photo.resultPhoto.destroy() if @isEdit and @photo.resultPhoto

		# @photo.chooser.onChange (file) -> 
		# 	Tesseract.recognize(file).then((result) -> console.log result)

		# btn : register or modify
		@inputScore.btn.onClick => 
			score = @inputScore.score.value

			
			if _.isEmpty score then alert '점수를 입력해 주세요.'
			else if parseInt(score) > 300 then alert '점수는 300점을 넘을 수 없어요.'
			#
			else 
				# edit
				if @isEdit then @_reqUpdate(parseInt(score), @datetime)
				# create
				else @_reqCreate(parseInt(score), @datetime)
					
		# 수정일 때 데이터 바인딩
		@bind(@data) if @isEdit

	# 데이터 바인딩
	bind: (data) ->
		@data = data

		# 날짜
		@dateField.value = moment(@data.updateAt).format('YYYY-MM-DD')

		# 사진
		if @data.resultPhotoUrl
			@photo.resultPhoto = new Layer
				point: Align.center
				width: 680, height: 122
				image: @data.resultPhotoUrl
				parent: @photo
			@photo.resultPhoto.sendToBack()
			@photo.text.visible = false

		# 점수
		@inputScore.score.value = @data.score

	# 닫기
	onClose: =>
		Firebase.get().dismissLoading()
		BowlingClub.get().fc.showPrevious()
		BowlingClub.get().fc.once Events.TransitionStop, => @destroy()

	# 삭제
	onDelete: => @_reqDelete(@data.gameId)

	# 완료
	onDone: =>
		Firebase.get().dismissLoading()
		alert "#{parseInt(@inputScore.score.value)} 점 등록!!"

		@inputScore.score.clear()
		@photo.chooser.clear()
		
		@croppie.destroy()
		@croppie = new Croppie document.getElementById('cropimage'),
				enableOrientation: true
				mouseWheelZoom: true
				showZoomer: false
				viewport: { width: 680, height: 122 }


	# 요청 : 생성
	_reqCreate: (score, formatDate) ->

		Firebase.get().showLoading()
		Firebase.get().createGame(score, convertMillisecond(formatDate))
		.then (gameId) =>
			console.log gameId
			# exist result photo
			if @photo.chooser.isLoaded
				@croppie.result(type: 'blob', format: 'jpeg', quality: .6, size: width: 680, height: 122)
				.then (blob) => @_reqUploadResultPhoto(gameId, blob)
			# 
			else @onDone()
		.catch (error) -> alert error

	# 요청 : 업데이트
	_reqUpdate: (score, formatDate) ->
		return unless @data

		Firebase.get().showLoading()
		Firebase.get().updateGame(@data.gameId, score, convertMillisecond(formatDate))
		.then () =>
			# exist result photo
			if @photo.chooser.isLoaded
				@croppie.result(type: 'blob', format: 'jpeg', quality: .6, size: width: 680, height: 122)
				.then (blob) => @_reqUploadResultPhotoUpdate(@data.gameId, blob)
			# 
			else @onClose()
		.catch (error) -> alert error

	# 요청 : 삭제
	_reqDelete: () ->
		return unless @data

		Firebase.get().showLoading()
		Firebase.get().deleteGame(@data.gameId)
		.then () =>
			# 결과 사진이 있는 경우
			if @data.resultPhotoUrl then @_reqDeleteResultPhoto(@data.gameId)
			# 
			else @onClose()
		.catch (error) -> alert error

	# 요청 : 사진 업로드 : 생성
	_reqUploadResultPhoto: (gameId, blob) ->
		Firebase.get().uploadGameResult(gameId, blob)
		.then () => @onDone()
		.catch (error) -> alert error

	# 요청 : 사진 업로드 : 수정
	_reqUploadResultPhotoUpdate: (gameId, blob) ->
		Firebase.get().uploadGameResult(gameId, blob)
		.then () => @onClose()
		.catch (error) -> alert error

	# 요청 : 사진 삭제
	_reqDeleteResultPhoto: (gameId) ->
		Firebase.get().deleteGameResult(gameId)
		.then () => @onClose()
		.catch (error) -> alert error


	# 변환 : YYYY년 MM월 DD일 -> 밀리초
	convertMillisecond = (formatDate) -> 
		date = moment(formatDate, 'YYYY년 MM월 DD일')
		return Number(moment().set({'year': date.get('year'), 'month': date.get('month'), 'date': date.get('date')}).format('x'))

