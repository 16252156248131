'''
Bowling Club : Notification

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Notification extends BaseLayer
	@define 'clubId', @simpleProperty('clubId', null)
	@define 'waitMembers', @simpleProperty('waitMembers', null)

	constructor: (options = {}) ->
		super _.defaults options,
			name: 'notification'
			backgroundColor: 'white'
			
	create: () ->
		@setAppBar appbar = new AppBar
			width: @width
			isFullscreen: @isFullscreen
			title: '가입요청'
			backgroundColor: 'white'

		actionBack = appbar.setHome R.drawable.actionClose
		actionBack.onClick @onBack 
			
		wrap = new Layer y: appbar.maxY, size: @size, backgroundColor: ''

		if !_.isNull @waitMembers and !_.isEmpty @waitMembers
			y = 0
			for memberId of @waitMembers
				item = new Item
					y: wrap.contentFrame().height
					width: @width
					clubId: @clubId
					memberId: memberId
					parent: wrap

				item.onAccepted (memberId, _item) =>
					wrap.removeChild _item
					_item.destroy()
					
					@waitMembers = _.omitBy @waitMembers, (value, key) => return key == memberId
					@onChangeDate()

		else
			empty = new TextLayer
				x: Align.center, y: Align.center(-appbar.maxY/2)
				html: '알림이 없습니다.'
				fontSize: 50, fontWeight: 400
				textAlign: 'center'
				letterSpacing: -1
				color: R.color.grey_bcbcbc
				parent: wrap
			
		wrap.height = wrap.contentFrame().height + 30
		wrap.y = appbar.maxY
		wrap.y -= 40 if @isFullscreen

		@setContent(wrap)

		# Action ----------------------------------------------- /

		@onChangeDate = =>
			console.log wrap.children
			unless _.isEmpty wrap.children
				y = 0
				for item in wrap.children
					# item.y = y
					item.animate 
						y: y
						options: curve: 'spring(400,35,0)'
					y += item.height
			else
				wrap.size = @size
				empty = new TextLayer
					x: Align.center, y: Align.center(-appbar.maxY)
					html: '알림이 없습니다.'
					fontSize: 50, fontWeight: 400
					textAlign: 'center'
					letterSpacing: -1
					color: R.color.grey_bcbcbc
					parent: wrap

			wrap.height = wrap.contentFrame().height + 30
			wrap.y = appbar.maxY
			wrap.y -= 40 if @isFullscreen

	onBack: =>
		BowlingClub.get().fc.showPrevious()
		BowlingClub.get().fc.once Events.TransitionStop, => @destroy()


class Item extends Layer

	Events.Accepted = 'accepted'

	@define 'clubId', @simpleProperty('clubId', null)
	@define 'memberId', @simpleProperty('memberId', null)

	constructor: (options = {}) ->
		super _.defaults options,
			name: 'item'
			height: 160
			backgroundColor: 'white'

		line = new Layer
			x: 33, y: Align.bottom
			width: @width - 33, height: 1
			backgroundColor: R.color.grey_ececec
			parent: @
			
		@photo = new Photo
			x: 33, y: Align.center
			size: 120
			borderColor: R.color.grey_979797, borderWidth: 1
			parent: @
			
		@email = new TextLayer
			x: @photo.maxX + 20, y: Align.bottom(-20)
			html: 'id@domain.com'
			fontSize: 30, fontWeight: 300
			color: R.color.grey_646464
			parent: @
			
		@displayName = new TextLayer
			x: @photo.maxX + 18, y: @email.y - 9 - 35
			html: 'displayName'
			fontSize: 50, fontWeight: 500
			lineHeight: 1
			letterSpacing: 0.7
			color: R.color.grey_4a4a4a
			parent: @
			
		@accept = new TextLayer
			x: Align.right(-33), y: @photo.y
			html: '승인'
			width: 160, height: 60
			fontSize: 32, fontWeight: 500
			lineHeight: 2
			textAlign: 'center'
			color: 'white'
			borderRadius: 5
			backgroundColor: R.color.blue_53aeff
			parent: @

		# Action ---------------------------------------------- /
		@accept.onClick => @_reqAccept()

		#
		@_reqGetUser()

	# data binding : memner info
	bind: (snapshot) ->
		unless _.isNull snapshot.val()

			data = snapshot.val()

			# @photo.image = data.photoUrl unless _.isEmpty data.photoUrl
			# if data.photoThumbUrl?
			# 	Firebase.get().downloadThumbURL(@memberId)
			# 	.then (url) => @photo.image = url unless _.isNull(url) and _.isEmpty(url)

			if data.photoThumbUrl?
				if data.photoThumbUrl == true
					Firebase.get().downloadThumbURL(@memberId)
					.then (url) => @photo.image url unless _.isNull(url) and _.isEmpty(url)
				else if data.photoThumbUrl == false
					console.log 'photoThumbUrl is false'
				else 
					@photo.image data.photoThumbUrl
					

			@displayName.html = data.displayName
			@email.html = data.email

	# request member info
	_reqGetUser: ->
		Firebase.get().onceGetUser(@memberId)
		.then (snapshot) => @bind(snapshot)
		.catch (error) -> alert error

	# request accept wait member joined
	_reqAccept: ->
		Firebase.get().joinClub(@memberId, @clubId)
		.then () => 
			@emit Events.Accepted, @memberId, @
		.catch (error) -> alert error

	# Event Helper
	onAccepted: (cb) -> @on Events.Accepted, cb