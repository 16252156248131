'''
Bowling Club : Firebase

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class FirebaseWeb extends Framer.BaseClass
	# Path
	DB = {}
	DB.USERS = "users/"
	DB.CLUBS = 'clubs/'
	DB.DASHBOARDS = "dashboards/"
	DB.GAMES = "games/"
	DB.CONFIGURATION = "configuration/"
	DB.ATTENDANCES = "attendances/"

	STORAGE = {}
	STORAGE.IMAGES = "images/"
	STORAGE.PROFILES = STORAGE.IMAGES + "profiles/"
	STORAGE.THUMB = STORAGE.PROFILES + "thumb/"
	STORAGE.CLUBS = STORAGE.IMAGES + "clubs/"
	STORAGE.GAMES = STORAGE.IMAGES + "games/"

	# Firebase 서버시간 사용
	TIMESTAMP = { ".sv": "timestamp" }

	# Real
	F = {
		js: "https://www.gstatic.com/firebasejs/4.13.0/firebase.js",
		config:
			apiKey: "AIzaSyBVq9b7mPayq0YOFtME_hQVzTsyIx3oJtU",
			authDomain: "kakao-bowling-club.firebaseapp.com",
			databaseURL: "https://kakao-bowling-club.firebaseio.com",
			projectId: "kakao-bowling-club",
			storageBucket: "kakao-bowling-club.appspot.com",
			messagingSenderId: "1040587265301"
	}

	# Dev
	# F = {
	# 	js: "https://www.gstatic.com/firebasejs/4.13.0/firebase.js",
	# 	config:
	# 		apiKey: "AIzaSyCFLoa1EZT3QXUF_Bp2ZuUZ0NQbMEvSCQM",
	# 		authDomain: "bowling-club-dev.firebaseapp.com",
	# 		databaseURL: "https://bowling-club-dev.firebaseio.com",
	# 		projectId: "bowling-club-dev",
	# 		storageBucket: "kakao-bowling-club.appspot.com",
	# 		messagingSenderId: "16773448523"
	# }

	@firebase = undefined
	@auth = undefined
	@database = undefined
	@storage = undefined

	@user = undefined
	@userData = undefined
	@clubIds = undefined
	@dashboard = undefined
	@configuration = undefined

	_loading = undefined

	@year = moment('2019').valueOf()
	
	# Constructor
	constructor: (callback) ->
		_loading = new Loading

		# Firebase load
		Utils.domLoadScript F.js, =>
			# Firebase Init
			firebase.initializeApp(F.config)
			
			# 
			@firebase = firebase
			@auth = firebase.auth()
			@database = firebase.database()
			@storage = firebase.storage()
			
			#
			callback @ if callback

	# 로딩 보기
	showLoading: -> _loading.show()
	# 로딩 감추기
	dismissLoading: -> _loading.dismiss()

	# Auth ---------------------------------------------------------------------------- //
	
	## sign out
	signOut: () ->
		return unless @auth
		
		@auth.signOut()
		.then () -> console.log "Sign-out successful.";
		.catch (error) -> console.log "An error happened. #{Utils.stringify(error)}";
		
	## log in : email
	signInWithEmailAndPassword: (email, password) ->
		return unless @auth

		@auth.signInWithEmailAndPassword(email, password)
		.then (user) =>
#			console.log "signInWithEmailAndPassword : #{Utils.stringify(user)}"
			@user = user
			# return @onceGetUserAndDashboard(@user.uid)

			@onceConfiguration()
			.then (snapshot) =>
				return @onceGetUserAndDashboard(@user.uid)
			, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## sing up: email
	createUserWithEmailAndPassword: (email, password, displayName) ->
		return unless @auth

		@auth.createUserWithEmailAndPassword(email, password)
		.then (user) =>
			# console.log "createUserWithEmailAndPassword : #{Utils.stringify(user)}"
			@user = user

			# DB에 USER + DASHBOARD 생성
			return @createUser(displayName, email)

		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## reset password : send email
	sendPasswordResetEmail: (email) ->
		return unless @auth

		_loading.show()
		@auth.sendPasswordResetEmail(email)
		.then () => 
			_loading.dismiss()
			# console.log "email sended";
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## state changed
	onAuthStateChanged: (listener) ->
		return unless @auth

		@auth.onAuthStateChanged listener

	
	# Database ------------------------------------------------------------------------ //


	# Configuration ============================================================ /
	onceConfiguration: () ->
		_loading.show()
		@database.ref(DB.CONFIGURATION).once('value')
		.then (snapshot) =>
			_loading.dismiss()

			@configuration = snapshot.val()
			@year = moment(@configuration.dashboard.limit.startAt).valueOf()

			Promise.resolve snapshot
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# Users ============================================================ /

	## create
	createUser: (displayName, email) ->
		return unless @database or @user

		# user
		user = {}
		user["#{Dto.user.email}"] = email
		user["#{Dto.user.displayName}"] = displayName
		# user["#{Dto.user.photoUrl}"] = ""
		# user["#{Dto.user.photoThumbUrl}"] = ""
		user["#{Dto.user.role}"] = Dto.ROLE.NORMAL
		
		# dashboard
		dashboard = {}
		dashboard.score = {}
		dashboard.score.previous = 0
		dashboard.score.current = 0
		dashboard.score.rising = 0

		dashboard.totalScore = 0
		dashboard.games = 0

		dashboard.average = {}
		dashboard.average.previous = 0
		dashboard.average.current = 0
		dashboard.average.rising = 0

		dashboard.likes = 0
		dashboard.likeUsers = []

		dashboard.clubs = []
		dashboard.updateAt = TIMESTAMP

		# data : user + dashboard
		data = {}
		data[DB.USERS + "#{@user.uid}/"] = user
		data[DB.DASHBOARDS + "#{@user.uid}/"] = dashboard

		# transaction
		@database.ref().update(data)
		.then () => 
			# USER + D
			return @onceGetUserAndDashboard(@user.uid)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## update
	updateUser: (displayName, photoUrl, photoThumbUrl) ->
		return unless @database or @user

		# data
		data = {}
		data["#{Dto.user.displayName}"] = displayName unless _.isEmpty(displayName)
		data["#{Dto.user.photoUrl}"] = photoUrl unless _.isEmpty(photoUrl)
		data["#{Dto.user.photoThumbUrl}"] = photoThumbUrl unless _.isEmpty(photoThumbUrl)

		# transaction
		@database.ref(DB.USERS).child(@user.uid).update(data)
		.then () =>
			return @onceGetUser(@user.uid)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## once
	onceGetUser: (userId) ->
		return unless @database or userId

		# transaction
		@database.ref(DB.USERS).child(userId).once('value')
		.then (snapshot) =>
			if @user and @user.uid == userId
				@userData = snapshot.val()

				# TODO 현재는 클럽가입 한개만 제공
				if @userData
					clubs = @userData.clubs
					if clubs and !_.isEmpty(clubs)
						picked = _.pickBy clubs, (value, key) -> return value
						keys = _.keys picked
						@clubIds = keys unless _.isEmpty keys

			return Promise.resolve(snapshot)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);		

	## once 
	onceGetUserAndDashboard: (userId) ->
		return unless @database or userId

		# transaction
		@onceGetUser(userId)
		.then (snapshot) =>
			return @onceGetDashboard(userId)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# Clubs ============================================================ /

	## create
	createClub: (clubName, brief) ->
		return unless @database or @user or clubName or brief

		# data
		data = {}
		data["#{Dto.club.allow}"] = false
		data["#{Dto.club.delete}"] = false
		data["#{Dto.club.name}"] = clubName
		data["#{Dto.club.brief}"] = brief
		data["#{Dto.club.master}"] = @user.uid
		data["#{Dto.club.members}"] = []
		data["#{Dto.club.createAt}"] = TIMESTAMP

		# transaction
		_loading.show()
		@database.ref(DB.CLUBS).push(data)
		.then (e) =>
			# _loading.dismiss()
			Promise.resolve(e)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);
		
	## update
	updateClub: (clubId, clubName, brief, coverUrl) ->
		return unless @database or clubId

		# data
		data = {}
		data["#{Dto.club.name}"] = clubName unless _.isEmpty(clubName)
		data["#{Dto.club.brief}"] = brief unless _.isEmpty(brief)
		data["#{Dto.club.coverUrl}"] = coverUrl unless _.isEmpty(coverUrl)

		# transaction
		_loading.show()
		@database.ref(DB.CLUBS).child(clubId).update(data)
		.then () =>
			_loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## once
	onceGetClub: (clubId) ->
		return unless @database or clubId

		# transaction
		@database.ref(DB.CLUBS).child(clubId).once('value')
		.then ((snapshot) => Promise.resolve(snapshot))
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## on list
	onceClubList: ->
		return unless @database

		# transaction
		_loading.show()
		@database.ref(DB.CLUBS).orderByKey().once('value')
		.then (snapshot) =>
			_loading.dismiss()
			Promise.resolve snapshot
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## on list
	onClubList: (callback) ->
		return unless @database

		# transaction
		_loading.show()
		@database.ref(DB.CLUBS).orderByKey().on 'value'
		, (snapshot) =>
			_loading.dismiss()
			callback snapshot if callback
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## off list
	offClubList: -> @database.ref(DB.CLUBS).off('value')

	## wait
	waitClub: (clubId) -> 
		return unless @database or @user or clubId

		# data
		data = {}
		# data[DB.USERS + @user.uid + "/" + "#{Dto.user.clubs}" + "/" + clubId] = false
		data[DB.CLUBS + clubId + "/" + "#{Dto.club.waitMembers}" + "/" + @user.uid] = true
		# data[DB.DASHBOARDS + @user.uid + "/" + "#{Dto.dashboard.clubs}" + "/" + clubId] = false

		# transaction
		_loading.show()
		@database.ref().update(data)
		.then () =>
			_loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## join
	joinClub: (userId, clubId) ->
		return unless @database or userId or clubId

		# data
		data = {}
		data[DB.USERS + userId + "/" + "#{Dto.user.clubs}" + "/" + clubId] = true
		data[DB.CLUBS + clubId + "/" + "#{Dto.club.waitMembers}" + "/" + userId] = null
		data[DB.CLUBS + clubId + "/" + "#{Dto.club.members}" + "/" + userId] = true
		data[DB.DASHBOARDS + userId + "/" + "#{Dto.dashboard.clubs}" + "/" + clubId] = true
		data[DB.DASHBOARDS + userId + "/" + "#{Dto.dashboard.updateAt}"] = TIMESTAMP

		# transaction
		_loading.show()
		@database.ref().update(data)
		.then () =>
			_loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## secession
	secessionClub: (clubId) ->
		return unless @database or @user or clubId

		# data
		data = {}
		data[DB.USERS + @user.uid + "/" + "#{Dto.user.clubs}" + "/" + clubId] = false
		data[DB.DASHBOARDS + @user.uid + "/" + "#{Dto.dashboard.clubs}" + "/" + clubId] = false
		data[DB.CLUBS + "/" + clubId + "/" + "#{Dto.clubs.memebers}" + "/" + @user.uid] = false

		# transaction
		_loading.show()
		@database.ref().update(data)
		.then () =>
			_loading.dismiss()
			# return @onceGetUserAndDashboard()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## join request memebers
	onWaitClubMembers: (clubId, callback) ->
		return unless @database or clubId

		# transaction
		@database.ref(DB.CLUBS).child(clubId).child(Dto.club.waitMembers).orderByValue().equalTo(true).on 'value'
		, (snapshot) =>
			callback snapshot if callback
		, (error) -> console.log error; Promise.reject(error);

	offWaitClubMembers: (clubId) -> @database.ref(DB.CLUBS).child(clubId).child(Dto.club.waitMembers).off()

	# Dashboards ============================================================ /

	## create : 사용안함 - 사용자 정보 생성 시 함께 생성함
	createDashboard: ->
		return unless @database or @user

		# data
		data = {}

		data.score = {}
		data.score.previous = 0
		data.score.current = 0
		data.score.rising = 0
		data.score.high = 0
		data.score.low = 0

		data.totalScore = 0
		data.games = 0

		data.average = {}
		data.average.previous = 0
		data.average.current = 0
		data.average.rising = 0

		data.likes = 0
		data.likeUsers = []

		data.clubs = []

		data.updateAt = TIMESTAMP

		# transaction
		_loading.show()
		@database.ref(DB.DASHBOARDS).child(@user.uid).set(data)
		.then () =>
			_loading.dismiss()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);


	## update : 게임 등록/수정/삭제 시 실행
	updateDashboard: ->
		return unless @database or @user

		# 1. 전체게임 가져오기
		@database.ref(DB.GAMES).child(@user.uid).orderByChild(Dto.game.updateAt).once('value')
		.then (snapshot) =>
			if snapshot and snapshot.val()
				if snapshot.numChildren() > 0
					games = snapshot.val()
					# console.debug games

					# - 정렬 
					value.gameId = key for key, value of games
					orderedGames = _.orderBy _.values(games), 'updateAt', 'desc'
					# console.log orderedGames

					# - 최근 게임 2개 정보
					recentGames = _.take orderedGames, 2
					# console.log recentGames

					# - 현재 게임 (마지막)
					gameCurrent = _.first recentGames
					# - 이전 게임 (마지막 - 1)
					gamePrev = _.last recentGames

					# 현재 게임 점수
					scoreCurrent = gameCurrent.score
					# 이전 게임 점수
					scorePrev = 0
					if _.size(recentGames) < 2 then	scorePrev = 0 
					else scorePrev = gamePrev.score


					# - 전체 점수
					totalScore = _.sumBy orderedGames, Dto.game.score
					# - 전체 게임수 
					totalGames = _.size orderedGames

					# # - 현재 에버
					# averageCurrent = Utils.roundWhole(totalScore / totalGames)
					# # - 이전 에버
					# averagePrev = 0
					# if _.size(recentGames) < 2 then averagePrev = 0
					# else averagePrev = Utils.roundWhole((totalScore - scoreCurrent) / (totalGames - 1))


					# [new] 제한게임수
					limitsGames = _.take(orderedGames, @configuration.dashboard.limit.toLast);

					limitsScore = _.sumBy limitsGames, Dto.game.score
					limitsGamesCount = _.size limitsGames

					# - 현재 에버
					averageCurrent = Utils.roundWhole(limitsScore / limitsGamesCount)
					# - 이전 에버
					averagePrev = 0
					if _.size(recentGames) < 2 then averagePrev = 0
					else averagePrev = Utils.roundWhole((limitsScore - scoreCurrent) / (limitsGamesCount - 1))

					# 업데이트 데이터
					dashboard = {}
					dashboard["#{Dto.dashboard.score}" + "/" +"#{Dto.dashboard.previous}"] = scorePrev
					dashboard["#{Dto.dashboard.score}" + "/" +"#{Dto.dashboard.current}"] = scoreCurrent
					dashboard["#{Dto.dashboard.score}" + "/" +"#{Dto.dashboard.rising}"] = scoreCurrent - scorePrev
					dashboard["#{Dto.dashboard.totalScore}"] = totalScore
					dashboard["#{Dto.dashboard.games}"] = totalGames
					dashboard["#{Dto.dashboard.average}" + "/" +"#{Dto.dashboard.previous}"] = averagePrev
					dashboard["#{Dto.dashboard.average}" + "/" +"#{Dto.dashboard.current}"] = averageCurrent
					dashboard["#{Dto.dashboard.average}" + "/" +"#{Dto.dashboard.rising}"] = Utils.roundWhole(averageCurrent - averagePrev)
					dashboard["#{Dto.dashboard.updateAt}"] = TIMESTAMP

#					console.log dashboard

					return @database.ref(DB.DASHBOARDS).child(@user.uid).update(dashboard)


			# 업데이트 데이터 : 게임수 없음
			dashboard = {}
			dashboard["#{Dto.dashboard.score}" + "/" +"#{Dto.dashboard.previous}"] = 0
			dashboard["#{Dto.dashboard.score}" + "/" +"#{Dto.dashboard.current}"] = 0
			dashboard["#{Dto.dashboard.score}" + "/" +"#{Dto.dashboard.rising}"] = 0
			dashboard["#{Dto.dashboard.totalScore}"] = 0
			dashboard["#{Dto.dashboard.games}"] = 0
			dashboard["#{Dto.dashboard.average}" + "/" +"#{Dto.dashboard.previous}"] = 0
			dashboard["#{Dto.dashboard.average}" + "/" +"#{Dto.dashboard.current}"] = 0
			dashboard["#{Dto.dashboard.average}" + "/" +"#{Dto.dashboard.rising}"] = 0
			dashboard["#{Dto.dashboard.updateAt}"] = TIMESTAMP
			
			return @database.ref(DB.DASHBOARDS).child(@user.uid).update(dashboard)

		, (error) -> console.log error; Promise.reject(error);

	## once
	onceGetDashboard: (userId) ->
		return unless @database or userId

		# transaction
		@database.ref(DB.DASHBOARDS).child(userId).once('value')
		.then (snapshot) =>
			if @user and @user.uid == userId
				@dashboard = snapshot.val()
			Promise.resolve(snapshot)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);	

	## on
	onDashboard: (callback) ->
		return unless @database or @user

		# transaction
		_loading.show()
		@database.ref(DB.DASHBOARDS).child(@user.uid).on 'value'
		, (snapshot) =>
			_loading.dismiss()
			callback snapshot if callback
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## on
	onDashboardList: (clubId, callback) ->
		return unless @database or clubId

		# transaction
		_loading.show()
		@database.ref(DB.DASHBOARDS).orderByChild(Dto.dashboard.clubs + "/" + clubId).equalTo(true).on 'value'
		, (snapshot) =>
			_loading.dismiss()
			callback snapshot if callback
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	offDashboardList: -> @database.ref(DB.DASHBOARDS).off('value')

	## like
	updateLike: (userId) ->
		return unless @database or userId

		# transaction
		_loading.show()
		@database.ref(DB.DASHBOARDS).child(userId).transaction (dashboard) =>
			if dashboard
				# increase
				dashboard.likes++
				# 
				dashboard.likeUsers = {} unless dashboard.likeUsers
				dashboard.likeUsers[@user.uid] = true;

			return dashboard;
		,  (error, committed, snapshot) =>
			_loading.dismiss()

			if (error) then console.log('Transaction failed abnormally!', error);
			else if (!committed) then console.log('We aborted the transaction (because already exists).');
			else console.log('User like added!');
			
			# console.log("dahsboard data: ", snapshot.val());
			
	
	# Games ============================================================ /

	## 생성
	createGame: (score, timestamp) ->
		return unless @database or @user or score or timestamp

		# game
		game = {}
		game.score = score
		game.resultPhotoUrl = ""
		game.createAt = TIMESTAMP
		game.updateAt = timestamp

		@database.ref(DB.GAMES).child(@user.uid).push(game)
		.then (e) => 
			@updateDashboard()
			.then (() => Promise.resolve(e.key))
			, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## 업데이트
	updateGame: (gameId, score, timestamp) ->
		return unless @database or @user or gameId or score

		# game
		game = {}
		game["#{Dto.game.score}"] = score
		game["#{Dto.game.updateAt}"] = timestamp

		# transaction
		@database.ref(DB.GAMES).child(@user.uid).child(gameId).update(game)
		.then () => 
			@updateDashboard()
			.then (() => Promise.resolve())
			, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## 업데이트 : 결과 사진
	updateGameResultPhoto: (gameId, resultPhotoUrl) ->
		# game
		game = {}
		game["#{Dto.game.resultPhotoUrl}"] = resultPhotoUrl

		# transaction
		@database.ref(DB.GAMES).child(@user.uid).child(gameId).update(game)
		.then () => 
			Promise.resolve()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);		

	## delete
	deleteGame: (gameId) ->
		return unless @database or @user or gameId

		# transaction
		@database.ref(DB.GAMES).child(@user.uid).child(gameId).remove()
		.then () => 
			@updateDashboard()
			.then (() => Promise.resolve())
			, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## 목록
	onceGetGames: (userId, start, end) ->
		return unless @database or userId

		# transaction
		_loading.show()
		ref = @database.ref(DB.GAMES).child(userId)
		ref = ref.startAt(start).endAt(end).orderByChild(Dto.game.updateAt) if start and end
		ref.once('value')
		.then (snapshot) => 
			_loading.dismiss()
			Promise.resolve snapshot
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	## 목록 : year
	onceGetGamesYear: (userId, year) ->
		year = moment().year() unless @year
		start = moment([year, 0, 1, 0, 0, 0, 0]).valueOf();
		end = moment([year, 11, 31, 24, 0, 0, 0]).valueOf();
		return @onceGetGames userId, start, end

	onceGetGamesLast: (userId, recentCount) ->
		return unless @database or userId

		# transaction
		@database.ref(DB.GAMES).child(userId)
		.orderByChild(Dto.game.updateAt)
		.limitToLast(recentCount)
		.once('value')
		.then (snapshot) =>
			Promise.resolve snapshot
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);


	# Attendances ============================================================ /


	# Storage ------------------------------------------------------------------------ //

	## upload : profile photo
	uploadProfilePhoto: (file, blob, exif) ->
		return unless @user or @storage or file or blob

		customMetadata = { 'exif': JSON.stringify(exif, null) }

		@storage.ref(STORAGE.PROFILES).child(@user.uid).put(file, { contentType: file.type, customMetadata: customMetadata })
		.then (snapshot) =>
			originalUrl = snapshot.metadata.downloadURLs[0]
			console.log('uploadProfilePhoto : ', originalUrl)

			# update profile photo
			return @updateUser(undefined, originalUrl)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

		###
		@storage.ref(STORAGE.PROFILES).child(@user.uid).put(file, { contentType: file.type, customMetadata: customMetadata })
		.then (snapshot) =>
			originalUrl = snapshot.metadata.downloadURLs[0]
			console.log('uploadProfilePhoto : ', originalUrl)

			@storage.ref(STORAGE.THUMB).child(@user.uid).put(blob, { contentType: blob.type, customMetadata: customMetadata })
			.then (snapshot) =>
				thumbUrl = snapshot.metadata.downloadURLs[0]
				console.log('uploadProfilePhotoThumb : ', thumbUrl)

				# update profile photo
				return @updateUser(undefined, originalUrl, thumbUrl)
			, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);
		###

	## upload : club cover photo
	uploadClubCover: (clubId, file) ->
		return unless @storage or clubId or file

		_loading.show()
		@storage.ref(STORAGE.CLUBS).child(clubId).put(file, { contentType: file.type })
		.then (snapshot) =>
			url = snapshot.metadata.downloadURLs[0]
			console.log('uploadClubCover : ', url)

			# update cover photo
			return @updateClub(clubId, undefined, undefined, url)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);


	## 업로드 : 게임 결과 사진
	uploadGameResult: (gameId, blob) ->
		return unless @storage or @user or gameId or blob

		# transaction
		@storage.ref(STORAGE.GAMES).child(@user.uid).child(gameId).put(blob, { contentType: blob.type })
		.then (snapshot) =>
			url = snapshot.metadata.downloadURLs[0]
			console.log('uploadGameResult : ', url)

			# update game result photo
			return @updateGameResultPhoto(gameId, url)
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);	

	## 삭제 : 게임 결과 사진
	deleteGameResult: (gameId) ->
		return unless @storage or @user or gameId

		# transaction
		@storage.ref(STORAGE.GAMES).child(@user.uid).child(gameId).delete()
		.then () =>
			Promise.resolve()
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	##
	downloadThumbURL: (uid) ->
		return unless @storage or uid

		# transaction
		@storage.ref(STORAGE.THUMB).child(uid).getDownloadURL()
		.then (url) =>
			Promise.resolve url
		.catch (error) -> _loading.dismiss(); console.log error; Promise.reject(error);



	# Debug ------------------------------------------------------------------------ //

	test: ->
		console.log STORAGE.IMAGES.PROFILES

# Singleton pattern
class Firebase
	instance = null
	@get: (cb) -> instance ?= new FirebaseWeb(cb)

module.exports = Firebase if module?
Framer.Firebase = Firebase