'''
Widget : Copyright

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Copyright extends TextLayer
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'copyright'
			x: Align.center, y: Align.bottom
			width: 410, height: 45
			html: 'Copyright(c)2017 threeword All rights reserved.'
			fontSize: 18, fontWeight: 500
			textAlign: 'center'
			color: R.color.grey_464646