Dto = {}

Dto.ROLE = {}
Dto.ROLE.NORMAL = 'normal'
Dto.ROLE.MASTER = 'master'
Dto.ROLE.ADMIN = 'admin'


Dto.user = {}
Dto.user.displayName = 'displayName'
Dto.user.email = 'email'
Dto.user.photoUrl = 'photoUrl'
Dto.user.photoThumbUrl = 'photoThumbUrl'
Dto.user.clubs = 'clubs'
Dto.user.role = 'role'


Dto.club = {}
Dto.club.name = 'name'
Dto.club.brief = 'brief'
Dto.club.master = 'master'
Dto.club.members = 'members'
Dto.club.coverUrl = 'coverUrl'
Dto.club.waitMembers = 'waitMembers'
Dto.club.createAt = 'createAt'
Dto.club.allow = 'allow'
Dto.club.delete = 'delete'


Dto.dashboard = {}
Dto.dashboard.average = 'average'
Dto.dashboard.games = 'games'
Dto.dashboard.score = 'score'
Dto.dashboard.totalScore = 'totalScore'
Dto.dashboard.likes = 'likes'
Dto.dashboard.likeUsers = 'likeUsers'
Dto.dashboard.updateAt = 'updateAt'
Dto.dashboard.user = 'user'
Dto.dashboard.current = 'current'
Dto.dashboard.previous = 'previous'
Dto.dashboard.rising = 'rising'
Dto.dashboard.clubs = 'clubs'


Dto.game = {}
Dto.game.score = 'score'
Dto.game.resultPhotoUrl = 'resultPhotoUrl'
Dto.game.createAt = 'createAt'
Dto.game.updateAt = 'updateAt'

Dto.attendance = {}

module.exports = Dto if module?
Framer.Dto = Dto