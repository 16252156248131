'''
Bowling Club : New Club

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.NewClub extends BaseLayer
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'newclub'
			backgroundColor: 'black'
			
	create: ->
		super
		
		@setAppBar appbar = new AppBar
			width: @width
			title: 'New Club'
			titleColor: 'white'
			isFullscreen: @isFullscreen
			
		actionClose = appbar.setHome R.drawable.actionClose_w
		appbar.statusBg.opacity = 0
		
		actionClose.onClick @onClose
		
		#
		wrap = new Layer y: appbar.maxY, width: 570, backgroundColor: ''
		
		wrap.guide = new TextLayer
			x: Align.center, y: 10
			width: 680, height:  318
			html: "<span style='font-weight:#{500};'>클럽 등록 시</span><br/>
			신청하신 분이 자동으로 클럽 대표로 설정되므로 클럽 대표이신 분만 신청해 주시기 바랍니다.<br/><br/>
			<span style='font-weight:#{500};'>클럽 신청 후</span><br/>
			목록에는 즉시 노출되지만 <font color='#ffed00'>관리자의 승인</font> 후에 이용할 수 있습니다."
			fontSize: 36
			color: 'white'
			parent: wrap
		
		# photo
		wrap.photo = new Layer
			x: Align.center, y: wrap.guide.maxY + 68
			size: 228
			borderRadius: 114, borderColor: 'white', borderWidth: 3
			backgroundColor: 'rgba(0,0,0,.3)'
			parent: wrap
		wrap.photo.label = new TextLayer
			point: Align.center
			html: "B"
			fontSize: 100
			fontWeight: 500
			letterSpacing: -1.9
			textAlign: 'center'
			color: R.color.grey_bcbcbc
			parent: wrap.photo
		wrap.photo.preview = new Layer
			point: Align.center
			size: wrap.photo.size
			borderRadius: wrap.photo.borderRadius, borderColor: wrap.photo.borderColor, borderWidth: wrap.photo.borderWidth
			backgroundColor: ''
			parent: wrap.photo
		wrap.photo.camera = new Layer
			x: Align.right, y: Align.bottom
			size: 64
			borderRadius: 32, borderColor: 'black', borderWidth: 3
			backgroundColor: R.color.accentColor
			parent: wrap.photo
		wrap.photo.camera.icon = new Layer _.extend R.drawable.camera,
			point: Align.center
			parent: wrap.photo.camera
		wrap.photo.camera.file = new InputPhoto
			point: Align.center
			size: wrap.photo.camera.size
			preview: wrap.photo.preview
			parent: wrap.photo.camera
		
		# club name
		wrap.clubName = new Input
			y: wrap.photo.maxY + 68
			width: wrap.width
			icon: R.drawable.pin
			type: "text"
			placeHolder: "Club Name", placeHolderFocus: "Club Name"
			pattern: ""
			parent: wrap
		
		# brief
		wrap.brief = new Input
			y: wrap.clubName.maxY + 48
			width: wrap.width
			icon: R.drawable.pin
			type: "text"
			placeHolder: "Brief Introduction", placeHolderFocus: "Brief Introduction"
			pattern: ""
			parent: wrap
		
		# # master
		# wrap.master = new Input
		# 	y: wrap.brief.maxY + 48
		# 	width: wrap.width
		# 	icon: R.drawable.email
		# 	type: "text"
		# 	placeHolder: "Master Email", placeHolderFocus: "Master Email"
		# 	pattern: ""
		# 	parent: wrap
			
		wrap.register = new Layer
			y: wrap.brief.maxY + 98
			width: 570, height: 100
			borderColor: R.color.accentColor, borderWidth: 5
			backgroundColor: ''
			parent: wrap
		wrap.register.bar = new Layer
			x: 35, y: Align.center
			width: 200, height: 10
			backgroundColor: 'white'
			parent: wrap.register
		wrap.register.label = new TextLayer
			x: Align.right(-30), y: Align.center
			width: 260, height: 40
			html: 'REGISTER NOW'
			fontSize: 35
			fontWeight: 500
			textAlign: 'center'
			color: 'white'
			backgroundColor: ''
			parent: wrap.register
		
		#
		wrap.height = wrap.contentFrame().height + 40
		wrap.x = (@width - wrap.width) / 2
		wrap.y = appbar.maxY
		wrap.y -= 40 if @isFullscreen
			
		@setContent(wrap)

		# Action ------------------------------------------------------------ /
		wrap.register.onClick =>
			clubName = wrap.clubName.value
			brief = wrap.brief.value
			# email = wrap.master.value
			file = wrap.photo.camera.file.value

			if _.isEmpty clubName then alert '클럽명을 입력해 주세요.'
			else if _.isEmpty brief then alert '간략한 클럽 설명을 입력해 주세요.'
			# else if _.isEmpty email then alert '클럽장의 이메일을 입력해 주세요.'	
			# else if !Util.valid.email(email) then alert '이메일이 올바르지 않아요.'
			#
			else @_reqCreateClub clubName, brief, file

	# request : create club to db
	_reqCreateClub: (clubName, brief, file) ->
		Firebase.get().createClub(clubName, brief)
		.then (result) =>
			clubId = result.key
			# console.debug clubId

			# 사진 있으면 업로드하고 계정 정보 생성
			if file 
				console.log '사진있음'
				@_reqUploadPhoto(clubId, file)
			# 사진 없으면 게정 정보 생성
			else 
				console.log '사진없음'
				@_reqJoinClub(clubId)
		.catch (error) -> alert error

	# request : upload club cover photo to storage
	_reqUploadPhoto: (clubId, file) ->	
		Firebase.get().uploadClubCover(clubId, file)
		.then (snapshot) => @_reqJoinClub(clubId)
		.catch (error) -> alert error

	# request: join club
	_reqJoinClub: (clubId) ->
		Firebase.get().joinClub(Firebase.get().user.uid, clubId)
		.then () => @onClose()
		.catch (error) -> alert error

	onClose: =>
		BowlingClub.get().fc.showPrevious()
		BowlingClub.get().fc.once Events.TransitionStop, => @destroy()