'''
Bowling Club : Device

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Device extends Framer.BaseClass

	# Device : Apple Watch 42mm
	@define "width", @simpleProperty("width", 750)
	@define "height", @simpleProperty("height", 1334)
	@define "ratio", @simpleProperty("ratio", undefined)

	# Constructor
	constructor: (options = {}) ->
		super options

		#
		@ratio = Screen.width / @width
		# Framer.Device.contentScale = @ratio