'''
Widget : AppBar

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.AppBar extends Layer
	
	@define 'isFullscreen', @simpleProperty('isFullscreen', false)

	@define 'title',
		default: ''
		get: -> @_title
		set: (value) -> 
			@_title = value
			@toolbar.title.html = @_title if @toolbar?.title

	@define 'subTitle',
		default: ''
		get: -> @_subTitle
		set: (value) -> 
			@_subTitle = value
			@toolbar.subTitle.html = @_subTitle if @toolbar?.title

	@define 'titleColor', @simpleProperty('titleColor', R.color.grey_4a4a4a)

	@define 'optionMenuX', @simpleProperty('optionMenuX', 0)
	# @define 'optionMenuX', @simpleProperty('optionMenuX', 24)
	
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'appbar'
			backgroundColor: ''

		@statusBg = new Layer width: @width, height: 40, visible: false, backgroundColor: 'black', parent: @
		if @isFullscreen
			@height = 136
			@statusBg.visible = true
		else  @height = 96
		
		@toolbar = new Layer
			y: if @isFullscreen then 40 else 0
			width: @width, height: 96
			backgroundColor: ''
			parent: @
			
		if @title?
			@toolbar.title = new TextLayer
				y: Align.center
				width: @toolbar.width, height: 46
				html: @title
				fontSize: 40, fontWeight: 500
				letterSpacing: -1.3 
				color: @titleColor
				textAlign: 'center'
				parent: @toolbar

		unless _.isEmpty @subTitle
			@toolbar.subTitle = new TextLayer
				y: 53
				width: @toolbar.width, height: 35
				html: @subTitle
				fontSize: 30, fontWeight: 400
				letterSpacing: -1
				color: R.color.grey_AEAEAE
				textAlign: 'center'
				parent: @toolbar
			@toolbar.title.y = 11


		@toolbar.home = new Button
			x: 0, y: Align.center
			width: 96, height: 96
			# size: 52
			pressed: 'rgba(0,0,0,.1)'
			backgroundColor: ''
			parent: @toolbar

		# @toolbar.home = new Button
		# 	x: 24, y: Align.center
		# 	size: 52
		# 	pressed: 'rgba(0,0,0,.1)'
		# 	backgroundColor: ''
		# 	parent: @toolbar
			
		@toolbar.menus = new Layer
			x: Align.right, y: Align.center
			width: @toolbar.width, height: @toolbar.height
			backgroundColor: ''
			parent: @toolbar

	# 홈 버튼
	setHome: (icon) ->
		new Layer _.extend icon,
			point: Align.center
			parent: @toolbar.home

		return @toolbar.home
		
	# 옵션 메뉴 : 아이콘
	addOptionMenu: (icon) ->
		menuWrapper = new Button
			width: 96, height: 96
			pressed: 'rgba(0,0,0,.1)'
			backgroundColor: ''
			parent: @toolbar.menus

		menu = new Layer _.extend icon,
			point: Align.center
			parent: menuWrapper

		menuWrapper.x = Align.right(-@optionMenuX);
		menuWrapper.centerY()
		
		@optionMenuX += menuWrapper.width
		# @optionMenuX += menuWrapper.width + 21

		return menuWrapper

	# 옵션 메뉴 : 레이블
	addOptionMenuLabel: (label) ->
		menu = new TextLayer
			html: label
			fontSize: 38, fontWeight: 500
			textAlign: 'right'
			letterSpacing: -1
			padding: {right:24}
			color: R.color.grey_4a4a4a
			parent: @toolbar.menus

		menu.x = Align.right(-@optionMenuX)
		menu.custom = @optionMenuX
		menu.centerY()

		menu.onChange 'html', -> 
			Util.text.autoSize @
			@x = Align.right(-@custom)

		@optionMenuX += menu.width
		# @optionMenuX += menu.width + 21

		return menu