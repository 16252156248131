# Cookie
Cookie = {}

# Set
Cookie.set = (key, value, expiredDays) ->
	expiredDate = new Date()
	expiredDate.setDate(expiredDate.getDate() + expiredDays)
	document.cookie = key + "=" + escape(value) + (if _.isNull(expiredDays) then "" else "; expires=" + expiredDate.toGMTString())
	
# Get
Cookie.get = (key) ->
	cookieName = key + '='
	cookieData = document.cookie
	start = cookieData.indexOf(cookieName)
	cookieValue = ''
	
	if start isnt -1
		start += cookieName.length
		end = cookieData.indexOf(';', start)
		if end is -1 then end = cookieData.length
		cookieValue = cookieData.substring(start, end)
		
	return unescape(cookieValue)

# Delete
Cookie.del = (key) ->
	expiredDate = new Date()
	expiredDate.setDate(expiredDate.getDate() - 1)
	document.cookie = key + "= " + "; expires=" + expiredDate.toGMTString()


module.exports = Cookie if module?
Framer.Cookie = Cookie