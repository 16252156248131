'''
Bowling Club : Clubs

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Clubs extends BaseLayer
	@define 'isFromMy', @simpleProperty('isFromMy', false)

	constructor: (options = {}) ->
		super _.defaults options,
			name: 'clubs'
			backgroundColor: 'black'

	create: ->
		super
		
		wrap = new Layer size: @size, backgroundColor: ''
		wrap.label = new TextLayer
			x: 33
			html: 'Clubs'
			fontSize: 120
			fontWeight: 500
			letterSpacing: -2.2
			color: 'white'
			parent: wrap
		
		wrap.skip = new TextLayer
			x: Align.right(-40), y: 74
			html: '나중에 가입'
			fontSize: 38, fontWeight: 500
			textAlign: 'right'
			color: 'white'
			parent: wrap

		@clubs = new Layer
			width: wrap.width, height: 1
			y: wrap.label.maxY + 32
			backgroundColor: ''
			parent: wrap

		new Layer _.extend R.drawable.actionAdd,
			point: Align.center
			parent:
				wrap.add = new Layer
					x: Align.center, y: @clubs.maxY + 20
					size: 100
					borderRadius: 50
					backgroundColor: R.color.accentColor
					parent: wrap

		@clubs.onChange 'height', onChangeHeight = => 
			wrap.add.y = @clubs.maxY + 20
			wrap.height = wrap.contentFrame().height + 30
			wrap.x = (@width - wrap.width) / 2
			wrap.y = 36
			
		onChangeHeight()

		@setContent(wrap)

		# Action ---------------------------------------------------- /

		wrap.skip.onClick @onSkip
		wrap.add.onClick @onNewClub

		# Data
		@_reqClubList()

	onSkip: =>
		# console.log @isFromMy
		unless @isFromMy
			home = new Home
			home.create()
			BowlingClub.get().fc.showNext home, scroll: false
			BowlingClub.get().fc.once Events.TransitionStop, => home.bind(); @destroy()
		else 
			BowlingClub.get().fc.showPrevious scroll: false
			BowlingClub.get().fc.once Events.TransitionStop, => @destroy()

	onNewClub: =>
		newClub = new NewClub
		newClub.create()
		BowlingClub.get().fc.showOverlayBottom newClub, scroll: false
		BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
			BowlingClub.get().fc.once Events.TransitionEnd, (a, b, direction) =>
				@_reqClubList() if b is this and direction == 'back'


	bind: (snapshot) =>
		return if _.isNull snapshot.val()

		clubs = snapshot.val();

		# clear
		child.destroy() for child in @clubs.children
		
		# club list
		y = 0
		for clubId, data of clubs
			item = new Club
				x: Align.center, y: y
				clubId : clubId
				data: data
				parent: @clubs
			y += 250 + 20

			item.onWaitDone (clubId) => @onSkip()
			@clubs.height = @clubs.contentFrame().height

	# request : club list
	_reqClubList: -> 
		Firebase.get().onceClubList()
		.then (snapshot) => @bind snapshot
		.catch (error) -> alert error