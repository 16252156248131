'''
Bowling Club : Profile : Clubs

@auther Jungho song (dev@threeword.com)
@since 2017.05.03
'''
class exports.ProfileClubs extends Layer
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'profile-club'
			height: 58
			backgroundColor: ''

		@clubList = []
		@isEdit = options.isEdit ?= false

		@add = new ClubAddItem visible: @isEdit, parent: @
		@size = @contentFrame()

	#
	onChangeClubList: (clubId) =>
		unless _.isEmpty @clubList
			x = 0; y = 0
			for club in @clubList
				club.x = x
				x += club.width + 13

				@size = @contentFrame()
				if(@width > @parent.width)
					x = 0; y += club.height + 13
					club.x = x
					club.y = y
					@size = @contentFrame()

				@add.props = 
					x: club.maxX + 13
					y: y
				@size = @contentFrame()

				if(@width > @parent.width)
					x = 0; y += club.height + 13
					@add.props = x: x, y: y
					@size = @contentFrame()
		else
			@add.animate x: 0, y: 0, options: curve: 'spring'
			@size = @contentFrame()

	# 데이터 바인딩
	bind: (userData) ->

		#
		clubs = userData.clubs
		if clubs and !_.isEmpty(clubs)
			picked = _.pickBy clubs, (value, key) -> return value
			keys = _.keys picked
			clubIds = keys unless _.isEmpty keys

			# TODO 클럽이 여러개면 그리드로 배치
			if clubIds
				sibling.destroy() for sibling in @add.siblings
				@clubList = []
				for clubId in clubIds
					item = new ClubItem
						clubId: clubId
						isEdit: @isEdit
						parent: @
					@clubList.push item

					item.onLoaded => @onChangeClubList()
					item.onSecessioned (clubId, _item) =>
						@removeChild _item
						_item.destroy()

						_.remove @clubList, (obj) -> return obj.clubId == clubId
						_.remove Firebase.get().clubIds, (_clubId) -> return _clubId == clubId

						@onChangeClubList()


'''
Bowling Club : Profile : Clubs : Item

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''		
class ClubItem extends Layer

	Events.Loaded = 'loaded'
	Events.Secessioned = 'secessioned'

	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			height: 58
			borderRadius: 100, borderColor: R.color.grey_979797, borderWidth: 1
			backgroundColor: 'rgba(216,216,216,.3)'


		@isEdit = options.isEdit ?= false
		@clubId = options.clubId ?= ''
			
		@text = new TextLayer
			x: 24, y: Align.center
			html: '-'
			fontSize: 38, fontWeight: 400
			letterSpacing: -1.6
			color: R.color.grey_6b6b6b
			parent: @
		
		if @isEdit
			@delete = new Layer _.extend R.drawable.delete,
				x: @text.maxX + 13, y: Align.center
				parent: @
		
		@size = @contentFrame().width + 45
		@height = 58

		# Action ----------------------------------------- //
		@text.onChange 'html', =>
			Util.text.autoSize @text
			@text.width += 4

			@delete.x = @text.maxX + 13 if @isEdit

			@size = @contentFrame().width + 45
			@height = 58

		if @isEdit
			@delete.onClick => 
				isDelete = confirm "\"#{@text.html}\" 클럽을 탈퇴하시겠습까?"
				@_reqSecessionClub @clubId if isDelete
		#
		@_reqJoinedClub @clubId

	bind: (snapshot) ->
		data = snapshot.val()
		if data
			@text.html = data.name

		@emit Events.Loaded, @clubId

	# joined club
	_reqJoinedClub: (clubId) ->
		Firebase.get().onceGetClub(clubId)
		.then (snapshot) => @bind(snapshot)
		.catch (error) -> alert error

	# secession club
	_reqSecessionClub: (clubId) ->
		Firebase.get().secessionClub(clubId)
		.then (snapshot) => @emit Events.Secessioned, clubId, @
		.catch (error) -> alert error

	# Event Helper
	onLoaded: (cb) -> @on Events.Loaded, cb
	onSecessioned: (cb) -> @on Events.Secessioned, cb


'''
Bowling Club : Profile : Clubs : Add Item

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class ClubAddItem extends Layer
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			height: 58
			borderRadius: 100, borderColor: R.color.grey_979797, borderWidth: 1
			backgroundColor: 'rgba(131,131,131,.6)'

		@text = new TextLayer
			x: 24, y: Align.center
			height: 43
			html: '클럽 가입'
			fontSize: 38, fontWeight: 400
			lineHeight: 1.1
			letterSpacing: -1.6
			color: 'white'
			parent: @
		
		@add = new Layer _.extend R.drawable.add,
			x: @text.maxX + 13, y: Align.center
			parent: @
		
		@size = @contentFrame().width + 40
		@height = 58