'''
Bowling Club : SharedPreference

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class SharedPreference extends Framer.BaseClass
	ACCOUNT = {}
	ACCOUNT.EMAIL = "email"
	ACCOUNT.PASSWORD = "password"
	ACCOUNT.EXPIRED_DAYS = 365

	# 생성자
	constructor: (options) ->
		super options

	# 계정 : 저장 
	setAccount: (email, password) ->
		emailEncrypt = Security.encrypt email
		passwordEncrypt = Security.encrypt password

		Cookie.set ACCOUNT.EMAIL, emailEncrypt, ACCOUNT.EXPIRED_DAYS
		Cookie.set ACCOUNT.PASSWORD, passwordEncrypt, ACCOUNT.EXPIRED_DAYS

	# 계정 : 불러오기
	getAccount: ->
		email = Security.decrypt Cookie.get(ACCOUNT.EMAIL)
		password = Security.decrypt Cookie.get(ACCOUNT.PASSWORD)

		if email and password then return { email: email, password: password }
		else return undefined

	# 계정 : 삭제
	clearAccount: ->
		Cookie.del ACCOUNT.EMAIL
		Cookie.del ACCOUNT.PASSWORD


# Singleton pattern
class Preference
	instance = null
	@get: -> instance ?= new SharedPreference

module.exports = Preference if module?
Framer.Preference = Preference