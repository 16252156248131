'''
Bowling Club : Profile : Graph

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''		
class exports.Graph extends Layer
	DUES = [
		{ text: '전체', value: 0 }
		{ text: '1달', value: 1 }
		{ text: '3달', value: 3 }
		{ text: '6달', value: 6 }
		{ text: '1년', value: 12 }
		{ text: '최근10게임', value: 10 }
	]

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: ''
			backgroundColor :''

		# 통계
		@total = new Layer
			x: Align.right(-31), y: 0
			width: 581, height: 114
			backgroundColor: ''
			parent: @
			
		@total.average = new Total
			text: 'Average'
			value: '0'
			parent: @total
			
		@total.game = new Total
			x: @total.average.maxX + 30
			text: 'Game'
			value: '0'
			parent: @total
					
		@total.size = @total.contentFrame()
		@total.x = Align.right(-31)

		# 차트
		@chart = new Layer
			y: @total.maxY + 28
			width: @width, height: 450
			shadowY: 1, shadowColor: 'rgba(0,0,0,.2)'
			backgroundColor: R.color.grey_f5f5f5
			parent: @
		@chart._element.id = 'chart'
		
		# 기간 표시
		@dueDate = new TextLayer
			x: Align.right(-30), y: 17 
			html: ''
			fontSize: 38, fontWeight: 300
			letterSpacing: -1
			textAlign: 'right'
			color: R.color.grey_4a4a4a
			parent: @chart
		@dueDate.width += 5

		# 기간 버튼들
		@dues = new Layer
			x: 25, y: @chart.maxY + 25
			backgroundColor: ''
			parent: @
		
		_this = @
		x = 0
		for d, i in DUES
			due = new Due
				x: x
				html: d.text
				value: d.value
				parent: @dues

			# 기본선택
			due.selected() if d.value == 0
			# 이벤트
			due.onClick -> _this.rangeDue @value
					
			x += due.width + 25
			@dues.size = @dues.contentFrame()

		# 차트
		@svg = d3.select("#chart")
			.append("svg")
			.attr("width", @chart.width)
			.attr("height", @chart.height)

		@size = @contentFrame()

		# 액션 ------------------------------------------------- /
		@total.average.onChange 'size', =>
			@total.game.x = @total.average.maxX + 30
			@total.size = @total.contentFrame()
			@total.x = Align.right(-31)

		@total.game.onChange 'size', =>
			@total.size = @total.contentFrame()
			@total.x = Align.right(-31)
			
	# 데이터 바인딩
	bind: (data) ->
		# 데이터 없음
		if _.isEmpty data
			@msg = new TextLayer
				point: Align.center
				html: '게임정보를 입력해주세요'
				fontSize: 40, fontWeight: 300
				letterSpacing: -1
				color: R.color.grey_d8d8d8
				parent: @chart

			return

		# 데이터 가공
		@games = _.chain(data)
				.flatMap (value) -> value[1]
				.forEach (value) -> value.formatDate = moment(value.updateAt).format('YYYY-MM-DD HH:mm:ss')
				.reverse()
				.value()

		# console.debug @games

		# 기간 : 전체
		@rangeDue 0
	
	# 데이터 바인딩 : 총
	bindTotal: (data) ->
		return if _.isEmpty data

		# - 점수
		totalScore = _.sumBy data, Dto.game.score
		# - 게임수 
		totalGames = _.size data

		@total.average.value = Utils.roundWhole(totalScore / totalGames)
		@total.game.value = totalGames

	# 데이터 바인딩 : 그래프
	bindGraph: (data) ->
		return if _.isEmpty data

		# 조회 기간
		f = _.first data
		l = _.last data

		# 기간
		@dueDate.html = moment(f.updateAt).format('YYYY.MM.DD') + ' - ' + moment(l.updateAt).format('YYYY.MM.DD')
		Util.text.autoSize @dueDate
		@dueDate.x = Align.right(-30)

		# 그래프
		svg = @svg
		svg.selectAll("*").remove();
		margin = {top: 70, right: 20, bottom: 30, left: 40}
		width = +svg.attr("width") - margin.left - margin.right
		height = +svg.attr("height") - margin.top - margin.bottom
		g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")

		# 데이터 50개 초과
		if _.size(data) > 50
			# 선형 : 채우기
			# x = d3.scaleTime().rangeRound([0, width])
			# y = d3.scaleLinear().rangeRound([height, 0])

			# area = d3.area()
			# 	.x (d) -> x(d.updateAt)
			# 	.y1 (d) -> y(d.score)

			# x.domain d3.extent(data, (d) -> return d.updateAt)
			# y.domain [0, d3.max(data, (d) -> return d.score)]
			# area.y0 y(0)

			# g.append("g")
			# 	.attr("class", "axis axis--x")
			# 	.attr("transform", "translate(0," + height + ")")
			# 	.call(d3.axisBottom(x))

			# g.append("g")
			# 	.attr("class", "axis axis--y")
			# 	.call(d3.axisLeft(y))
			# 	.append("text")
			# 	.attr("fill", "#000")
			# 	.attr("transform", "rotate(-90)")
			# 	.attr("y", 6)
			# 	.attr("dy", "0.71em")
			# 	.attr("text-anchor", "end")
			# 	.text("Score (점)")

			# g.append("path")
			# 	.datum(data)
			# 	.attr("fill", "steelblue")
			# 	.attr("d", area)

			# 선형 
			x = d3.scaleLinear().range([0, width])
			y = d3.scaleLinear().rangeRound([height, 0])

			line = d3.line()
				.curve(d3.curveCatmullRom)
				.x((d, i) -> x(i))
				.y((d) -> y(d.score))

			x.domain [0, data.length]
			y.domain [0, d3.max(data, (d) -> d.score)]

			# x 축
			g.append("g")
				.attr("transform", "translate(0," + height + ")")
				.call(d3.axisBottom(x))

			# y 축
			g.append("g")
				.call(d3.axisLeft(y))
				.append("text")
					.attr("fill", "#000")
					.attr("x", 0)
					.attr("y", -16)
					.attr("dy", "0.71em")
					.attr("text-anchor", "middle")
					.text("Score (점)")

			# 라인
			g.append("path")
				.datum(data)
				.attr("fill", "none")
				.attr("stroke", "steelblue")
				.attr("stroke-linejoin", "round")
				.attr("stroke-linecap", "round")
				.attr("stroke-width", 2)
				.attr("d", line)

			# 점
			g.selectAll('.dot')
				.data(data)
				.enter().append('circle')
				.attr("fill", "steelblue")
				.attr("stroke", "steelblue")
				.attr("stroke-width", 1)
				.attr('r', 2)
				.attr('cx', (d, i) -> x(i))
				.attr('cy', (d) -> y(d.score))
		else 
			# 막대
			x = d3.scaleBand().rangeRound([0, width]).padding(0.1)
			y = d3.scaleLinear().rangeRound([height, 0])

			x.domain data.map((d) -> d.score)
			y.domain [0, d3.max(data, (d) -> d.score)]

			# x 축
			g.append("g")
				.attr("transform", "translate(0," + height + ")")
				.call(d3.axisBottom(x))

			# y 축
			g.append("g")
				.call(d3.axisLeft(y))
				.append("text")
					.attr("fill", "#000")
					.attr("x", 0)
					.attr("y", -16)
					.attr("dy", "0.71em")
					.attr("text-anchor", "middle")
					.text("Score (점)")

			# 바
			g.selectAll(".bar")
				.data(data)
				.enter()
				.append("rect")
					.attr('fill', 'steelblue')
					.attr("x", (d) -> x(d.score))
					.attr("y", (d) -> y(d.score))
					.attr("width", x.bandwidth())
					.attr("height", (d) -> height - y(d.score))

	# 기간 설정
	rangeDue: (value) ->
		
		switch value
			# 기간 (1달, 3달, 6달)
			when 1, 3, 6
				data = _.filter @games, (game) -> game.updateAt > moment().subtract(value, 'month').format('x')
			# 기간 (1년)
			when 12
				data = _.filter @games, (game) -> game.updateAt > moment().subtract(1, 'year').format('x')
			# 최근 10게임
			when 10
				data = _.takeRight @games, 10
			# 전체
			else data = @games


		if _.isEmpty(data)
			alert "등록된 게임이 없어요!!\n볼링 좀 쳐요! (Ò ‸ Ó)"
		else
			# 총값
			@bindTotal data
			# 그래프
			@bindGraph data

'''
Bowling Club : Profile : Total : Average and Games

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''		
class Total extends Layer
	@define 'text', 
		get: -> @_text
		set: (value) -> 
			@_text = value
			if @textL
				@textL.html = value
				Util.text.autoSize @textL
				@textL.width += 10

				@valueL.x = @textL.maxX + 2
				
				@size = @contentFrame()
				@height = 114

	@define 'value',
		get: -> @_value
		set: (value) -> 
			@_value = value
			if @valueL
				@valueL.html = value 
				Util.text.autoSize @valueL

				@size = @contentFrame()
				@height = 114

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			height: 114
			backgroundColor: ''
			
		text = options.text
		value = options.value
			
		@textL = new TextLayer
			y: Align.bottom(-13)
			html: text
			fontSize: 50, fontWeight: 300
			letterSpacing: -2.5
			color: R.color.grey_4a4a4a
			parent: @
		@textL.width += 10
		
		@valueL = new TextLayer
			x: @textL.maxX + 2, y: Align.bottom
			html: value
			fontSize: 100, fontWeight: 400
			lineHeight: 1
			letterSpacing: -5
			style: 
				borderStyle: 'hidden hidden solid hidden'
			borderWidth: 5, borderColor: 'black'
			color: R.color.grey_4a4a4a
			parent: @
			
		@size = @contentFrame()
		@height = 114


'''
Bowling Club : Profile : Due

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''		
class Due extends TextLayer
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			height: 49
			fontSize: 43, fontWeight: 300
			letterSpacing: -2.5
			textAlign: 'center'
			color: R.color.grey_4a4a4a
			
		# 기본값
		@value = options.value ?= 0
		
		@states = 
			normal: fontWeight: 300
			selected: fontWeight: 500
				
		@width += 5
		
		@line = new Layer
			x: Align.center, y: @height + 10
			width: @width, height: 5
			backgroundColor: 'black'
			opacity: 0
			parent: @

		# 액션 ------------------------------------------------- /

		@onClick =>
			@selected()
			sibling.normal() for sibling in @siblings

	# 선택
	selected: -> 
		@stateSwitch 'selected'
		@line.opacity = 1
	
	# 기본
	normal: -> 
		@stateSwitch 'normal'
		@line.opacity = 0