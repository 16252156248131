# # Override : window prompt
# window.prompt = (message, defaults) =>
# 	iframe = document.createElement("IFRAME")
# 	iframe.style.display = "none"
# 	iframe.setAttribute("src", 'data:text/plain,')
# 	document.documentElement.appendChild(iframe)
# 	alertFrame = window.frames[0]
# 	result = alertFrame.window.prompt(message, defaults)
# 	iframe.parentNode.removeChild(iframe)
# 	return result;

# # Override : window alert
# window.alert = (message) =>
# 	iframe = document.createElement("IFRAME")
# 	iframe.style.display = "none"
# 	iframe.setAttribute("src", 'data:text/plain,')
# 	document.documentElement.appendChild(iframe)
# 	alertFrame = window.frames[0]
# 	result = alertFrame.window.alert(message)
# 	iframe.parentNode.removeChild(iframe)
# 	return result;
	
'''
Bowling Club

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class Application extends Framer.BaseClass
	# 플로우 컴포넌트
	# 플로우 컴포넌트 : 트랜지션
	Transitions = {}
	Transitions.overlayBottom = (nav, layerA, layerB, overlay) ->
		transition =
			layerB:
				show: {options: {curve: "spring(400, 42, 15)", time: .15}, x: Align.center, y: nav?.height - layerB?.height}
				hide: {options: {curve: "spring(300, 35, 0)", time: .3}, x: Align.center, y: nav?.height}
			overlay:
				show: {options: {time: 0.1}, opacity: .5, x: 0, y: 0, size: nav.size}
				hide: {options: {time: 0.1}, opacity: 0, x: 0, y: 0, size: nav.size}
				
	Transitions.overlay = (nav, layerA, layerB, overlay) ->
		transition =
			layerB:
				show: {options: {curve: "spring(500, 35, 0)"}, opacity: 1}
				hide: {options: {curve: "spring(500, 35, 0)"}, opacity: 0}
			overlay:
				show: {options: {time: 0.1}, opacity: .5, x: 0, y: 0, size: nav.size}
				hide: {options: {time: 0.1}, opacity: 0, x: 0, y: 0, size: nav.size}

	constructor: (options = {}) ->
		super options

		Firebase.get -> console.debug 'Firebase loaded'

		@fc = new FlowComponent
			width: Device.width
			height: Screen.height / Device.ratio
			scale: Device.ratio, originX: 0, originY: 0
			backgroundColor: 'white'

		# keypad hide
		document.addEventListener 'touchstart', (e) -> document.activeElement.blur() if (!isTextInput(e.target) && isTextInput(document.activeElement)) 

	# 화면이동 : 푸시
	nextPush: (layer, options = {}) ->
		if @fc
			@fc.showNext layer, _.defaults(options, scroll: false)

	# 화면이동 : 모달
	nextModal: (layer, options = {}) ->
		if @fc
			@fc.transition layer, Transitions.overlayBottom, _.defaults(options, scroll: false)

	# keypad hide
	isTextInput = (node) -> return ['INPUT', 'TEXTAREA'].indexOf(node.nodeName) != -1

# Singleton pattern
class BowlingClub
	instance = null
	@get: -> instance ?= new Application

module.exports = BowlingClub if module?
Framer.BowlingClub = BowlingClub