'''
Bowling Club : Home

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Home extends BaseLayer
	@define 'clubId', @simpleProperty('clubId', null)
	@define 'waitMembers', @simpleProperty('waitMembers', null)
	@define 'dashboards', @simpleProperty('dashboards', null)

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'home'
			backgroundColor: 'white'

	# 생성
	create: ->
		@setAppBar appbar = new AppBar
			width: @width
			isFullscreen: @isFullscreen
			title: 'Bowling Club'
			backgroundColor: 'white'
			
		acionHome = appbar.setHome R.drawable.actionPerson
		actionAdd = appbar.addOptionMenu R.drawable.actionAdd
		@actionNoti = appbar.addOptionMenu R.drawable.actionNoti
		@actionNoti.visible = false
		
		@actionNoti.dot = new Layer
			x: Align.right(-12), y: Align.top(12)
			size: 34
			borderRadius: 34, borderWidth: 3, borderColor: 'white'
			backgroundColor: '#F95353'
			visible: false
			parent: @actionNoti

		@actionNoti.dot.count = new TextLayer
			point: Align.center
			html: 22
			fontSize: 18
			textAlign: 'center'
			color: 'white'
			parent: @actionNoti.dot

			
		wrap = new Layer name: '.', y: appbar.maxY, size: @size, backgroundColor: ''
		
		@ranks = new Layer
			width: @width, height: 691
			backgroundColor: 'white'
			shadowY: 1, shadowColor: 'rgba(0,0,0,.2)'
			parent: wrap
			
		@ranks.rising = new Rank
			x: 28, y: 125
			width: 333, height: 375
			borderRadius: 7, borderWidth: 1, borderColor: '#ba3fe4'
			shadowBlur: 5, shadowSpread: 2, shadowColor: 'rgba(186, 63, 228, 0.3)'
			title: 'Rising Top 1'
			titleColor: '#ba3fe4'
			valueSize: 140
			transformFrame: x: 20, y: 20, width: 230, height: 295
			parent: @ranks
		# @ranks.rising.bind RANK.rising
		
		@ranks.average = new Rank
			x: 387, y: 35
			width: 333, height: 296
			borderRadius: 7, borderWidth: 1, borderColor: '#4b8edc'
			shadowBlur: 5, shadowSpread: 2, shadowColor: 'rgba(75, 142, 220, 0.3)'
			title: 'Average Top 1'
			titleColor: '#4b8edc'
			valueSize: 120
			transformFrame: x: 260, y: 20, width: 230, height: 295
			parent: @ranks
		# @ranks.average.bind RANK.average
		
		@ranks.games = new Rank
			x: 387, y: 357
			width: 333, height: 296
			borderRadius: 7, borderWidth: 1, borderColor: '#4bdc5c'
			shadowBlur: 5, shadowSpread: 2, shadowColor: 'rgba(75, 142, 220, 0.3)'
			title: 'Games Top 1'
			titleColor: '#4bdc5c'
			valueSize: 120
			transformFrame: x: 500, y: 20, width: 230, height: 295
			parent: @ranks
		# @ranks.games.bind RANK.games
		
		statusbarHeight = if @isFullscreen then 40 else 0
			
		@cards = new Layer
			y: @ranks.maxY
			width: @width
			backgroundColor: R.color.grey_f5f5f5
			parent: wrap
		@cards.sendToBack()

		@dashboard = new Dashboard
			width: @width
			parent: @cards
		@cards.size = @cards.contentFrame()

		wrap.height = wrap.contentFrame().height + 30
		wrap.y = appbar.maxY
		wrap.y -= statusbarHeight
				
		@setContent(wrap)

		@appbar = appbar
		@wrap = wrap

		# Action -------------------------------------------------- /

		acionHome.onClick => @onProfile Firebase.get().user.uid, Firebase.get().userData
		@actionNoti.onClick @onNoti
		actionAdd.onClick @onAdd

		# 클릭 : 에베상승
		@ranks.rising.onClick => 
			@onLeaderBoard
				title: 'Rising'
				keys: ['average.rising', 'average.current']
				orderBies: ['desc', 'desc']
				key: 'average.rising'

		# 클릭 : 에버
		@ranks.average.onClick =>
			@onLeaderBoard
				title: 'Average'
				keys: 'average.current'
				orderBies: 'desc'
				key: 'average.current'

		# 클릭 : 게임수
		@ranks.games.onClick =>
			@onLeaderBoard
				title: 'Games'
				keys: 'games'
				orderBies: 'desc'
				key: 'games'

		#
		@cards.onChange 'height', ->
			wrap.height = wrap.contentFrame().height + 30
			# wrap.y = appbar.maxY
			# wrap.y -= statusbarHeight

		@appbar.toolbar.title.onClick =>
			@showDropdown() if @clubsInfo?

	# 콜백 : 내정보
	onProfile: (userId, userData) ->
		@unbind()

		profile = new Profile userId: userId, userData: userData
		profile.create()
		BowlingClub.get().fc.showNext profile, scroll: false
		BowlingClub.get().fc.once Events.TransitionEnd, => profile.bind()

	# 콜백 : 알림 (가입승인요청)
	onNoti: =>
		@unbind()

		notification = new Notification clubId: @clubId, waitMembers: @waitMembers
		notification.create()
		BowlingClub.get().nextModal notification

	# 콜백 : 게임추가
	onAdd: =>
		@unbind()

		score = new Score
		score.create()
		BowlingClub.get().nextModal score

	# 콜백 : 리더보드
	onLeaderBoard: (options = {}) ->
		leaderBoard = new LeaderBoard options
		leaderBoard.create(@dashboards)
		BowlingClub.get().nextModal leaderBoard
		BowlingClub.get().fc.once Events.TransitionEnd, -> leaderBoard.bind()

	# 데이터 : 언바인딩
	unbind: ->
		if @clubId
			Firebase.get().offWaitClubMembers(@clubId)
			Firebase.get().offDashboardList()

		BowlingClub.get().fc.on Events.TransitionEnd, callback = (a, b, direction) => 
			if b is this and direction == 'back'
				BowlingClub.get().fc.off Events.TransitionEnd, callback
				@bind(@clubId)

	# 데이터 바인딩 
	bind: (clubId) ->
		'''
		+ 클럽 아이디가 넘어온 경우
			1. 클럽 변경

		+ 가입 클럽이 있는 경우

			1. 클럽회원 정보 불러오기 (랭킹, 대시보드)
			2. 알림 트리거

		+ 가입 클럽 없는 경우

			1. 내 대시보드만 불러온다
		'''
		if clubId
			if @clubId
				Firebase.get().offWaitClubMembers(@clubId)
				Firebase.get().offDashboardList()

			@clubId = clubId

			#
			@actionNoti.visible = true

			#
			console.time 'noti'
			@_reqNoti()
			console.time 'dashboards'
			@_reqDashboards()
			console.time 'club'
			@_reqClub()

		else if Firebase.get().clubIds and !_.isEmpty Firebase.get().clubIds
			#
			@clubId = Firebase.get().clubIds[0]

			#
			@actionNoti.visible = true

			if _.size(Firebase.get().clubIds) > 1 then 	@_reqClubInfos() else @bind(@clubId)

		else
			#
			@clubId = null

			#
			@actionNoti.visible = false
			@_reqDashboard()
			
	# data binding : notification
	bindNoti: (snapshot) =>
		console.timeEnd 'noti'

		@waitMembers = snapshot.val()
		unless _.isNull snapshot.val() and snapshot.numChildren() > 0
			@actionNoti.dot.visible = true
			@actionNoti.dot.count.html = snapshot.numChildren()
		else 
			@actionNoti.dot.visible = false
			@actionNoti.dot.count.html = 0

	# data binding : dashboards
	bindDashboards: (dashboards) =>
		console.timeEnd 'dashboards'

		console.time 'dashboards-render'

		if dashboards
			@dashboards = dashboards
			# console.log _.size(dashboards) if dashboards

			# rank
			value.uid = key for key, value of dashboards
			orderedDashboards = _.orderBy _.values(dashboards), 'updateAt', 'desc'
			removedNoGameUser = _.filter(orderedDashboards, (obj) -> return obj.games != 0)
			# console.debug orderedDashboards

			unless _.isEmpty removedNoGameUser
				# rising top
				rankRising = _.head(
					_.orderBy(
						removedNoGameUser
						, ['average.rising', 'average.current']
						, ['desc', 'desc']
					)
				)
				# console.debug rankRising
				@ranks.rising.bind rankRising, 'average.rising', 'average.current'
				
				# average top
				rankAverage = _.head(
					_.orderBy(
						removedNoGameUser
						, 'average.current'
						, 'desc'
					)
				)
				# console.debug rankAverage
				@ranks.average.bind rankAverage, 'average.current'
				
				# games top
				rankGames = _.head(
					_.orderBy(
						removedNoGameUser
						, 'games'
						, 'desc'
					)
				)
				# console.debug rankGames
				@ranks.games.bind rankGames, 'games'

			# each dashboard
			_this = @
			###
			child.destroy() for child in @cards.children
			y = 1
			for data in orderedDashboards
				dashboard = new Dashboard
					y: y
					width: @width
					data: data
					parent: @cards

				# user loaded
				y += dashboard.height + 20
				unless _.isEmpty removedNoGameUser
					dashboard.onUserLoaded (uid, data) =>
						@ranks.rising.bindUpdate data if rankRising.uid == uid
						@ranks.average.bindUpdate data if rankAverage.uid == uid
						@ranks.games.bindUpdate data if rankGames.uid == uid

					dashboard.onClick -> _this.onProfile @data.uid, @data.user

			@cards.height = @cards.contentFrame().height
			###

			_this = @
			@dashboardArr = [] unless @dashboardArr?
			y = 1
			for data, i in orderedDashboards
				dashboard = @dashboardArr[i]

				# 새로 만들기
				unless dashboard
					dashboard = new Dashboard
						y: y
						width: @width
						data: data
						parent: @cards

					y += dashboard.height + 20
					unless _.isEmpty removedNoGameUser
						dashboard.onUserLoaded (uid, data) =>
							@ranks.rising.bindUpdate data if rankRising.uid == uid
							@ranks.average.bindUpdate data if rankAverage.uid == uid
							@ranks.games.bindUpdate data if rankGames.uid == uid

					dashboard.onClick -> _this.onProfile @data.uid, @data.user

					#
					@dashboardArr.push dashboard

				# 데이터 갱신
				else dashboard.bind(data)

			@cards.height = @cards.contentFrame().height

			console.timeEnd 'dashboards-render'

	# data binding : dashboard
	bindDashboard: (dashboard) =>
		if dashboard
			dashboard.uid = Firebase.get().user.uid
			dashboard.user = Firebase.get().userData
			console.debug dashboard

			# rising top
			@ranks.rising.bind dashboard, 'average.rising', 'average.current'
			@ranks.average.bind dashboard, 'average.current'
			@ranks.games.bind dashboard, 'games'
			
			# each dashboard
			child.destroy() for child in @cards.children
			@cards.size = 0
			@dashboard = new Dashboard
				y: 1
				width: @width
				data: dashboard
				parent: @cards
			@cards.height = @cards.contentFrame().height

	# 데이터 바인딩 : 클럽정보
	bindClub: (snapshot) =>
		console.timeEnd 'club'

		return unless snapshot
		club = snapshot.val()

		membersCount = 0
		membersCount = _.countBy(_.values(club.members))['true'] if club?.members?

		@appbar.title = "#{club.name} (#{membersCount})"
		@appbar.title += " ▼" if @clubsInfo?

	bindClubs: (clubsInfo) =>
		return unless clubsInfo
		@clubsInfo = clubsInfo

		#
		@bind(@clubId)

	# 클럽선택
	showDropdown: () ->
		unless @dropdown?
			@dropdown = new Dropdown
				size: @size
				menus: @clubsInfo
				contentInset: top: @appbar.height
				parent: @

			# 선택
			@dropdown.onSelected (idx) =>
				@bind(@clubsInfo[idx].id)

			@dropdown.selected(@clubId);

		@dropdown.show()

	# request notification : wait join member
	_reqNoti: -> Firebase.get().onWaitClubMembers @clubId, @bindNoti

	# request dashboard
	_reqDashboard: -> Firebase.get().onDashboard (snapshot) => @bindDashboard snapshot.val()

	# request dashboards
	_reqDashboards: -> Firebase.get().onDashboardList @clubId, (snapshot) => @bindDashboards snapshot.val()

	# 요청 : 클럽정보
	_reqClub: ->
		Firebase.get().onceGetClub(@clubId)
		.then ((snapshot) => @bindClub(snapshot))
		, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

	# 요청 : 클럽정보들
	_reqClubInfos: () ->
		clubSize = _.size(Firebase.get().clubIds);

		console.log clubSize

		# 클럽이 여러개인 경우
		if clubSize > 1
			clubsInfo = []

			for clubId in Firebase.get().clubIds
				do (clubId) =>
					Firebase.get().onceGetClub(clubId)
					.then (snapshot) => 
						clubInfo = snapshot.val()
						clubInfo['id'] = clubId

						clubsInfo.push clubInfo

						# 
						@bindClubs(clubsInfo) if _.size(clubsInfo) == clubSize

					, (error) -> _loading.dismiss(); console.log error; Promise.reject(error);

class Dropdown extends Layer
	Events.SELECTED = 'selected'

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			visible: false
			backgroundColor: ''

		@menusData = options.menus
		@contentInset = options.contentInset

		@dim = new Layer
			size: @size
			opacity: 0
			backgroundColor: 'rgba(0,0,0,.3)'
			parent: @

		@menus = new Layer
			y: @contentInset.top
			width: @width
			backgroundColor: 'white'
			shadowColor: 'rgba(0,0,0,.24)', shadowY: 8, shadowBlur: 8
			parent: @

		count = 0
		for menuData in @menusData
			console.log menuData.name

			membersCount = 0
			membersCount = _.countBy(_.values(menuData.members))['true'] if menuData?.members?

			btn = new Layer
				width: @menus.width, height: 96
				x: 0, y: 96 * count
				backgroundColor: ''
				custom: count
				parent: @menus

			btn.label = new TextLayer
				point: Align.center
				html: "#{menuData.name} (#{membersCount})"
				fontSize: 38, fontWeight: 500
				letterSpacing: -1
				color: R.color.grey_4a4a4a
				parent: btn

			do (count) =>
				btn.onClick => @select count


			count++

		@menus.height = @menus.contentFrame().height;

		# 이벤트

		@dim.onClick => @dismiss()

	# 보이기/숨기기 토글
	toggle: -> if @isShow then @dismiss() else @show()

	select: (idx) ->
		i = 0
		for item in @menus.children
			if i++ == idx
				item.label.color = 'white'
				item.backgroundColor = 'black'
			else
				item.label.color = 'black'
				item.backgroundColor = ''

		@emit Events.SELECTED, idx, @
		@dismiss();

	selected: (clubId) ->
		idx = _.findIndex @menusData, (obj) => return obj.id == clubId
		console.log idx

		i = 0
		for item in @menus.children
			if i++ == idx
				item.label.color = 'white'
				item.backgroundColor = 'black'
			else
				item.label.color = 'black'
				item.backgroundColor = ''

	# 보이게
	show: ->
		@visible = true
		@dim.off Events.AnimationEnd
		@dim.animateStop()
		@dim.animate opacity: 1, options: time: .15

		#
		@menus.animateStop()
		@menus.animate opacity: 1, options: time: .15

		@isShow = true

	# 숨기기
	dismiss: ->

		#
		@menus.animateStop()
		@menus.animate opacity: 0, options: time: .15

		@dim.animateStop()
		@dim.animate opacity: 0, options: time: .15
		@dim.once Events.AnimationEnd, => @visible = false if @visible

		@isShow = false
		

	onSelected: (cb) -> @on Events.SELECTED, cb

