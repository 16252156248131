'''
Widget : Input

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Input extends Layer
	@define 'value', 
		get: -> @_value
		set: (value) -> 
			@_value = value
			@input.value = @_value if @input

	constructor: (options = {}) ->
		super _.defaults options,
			width: 570
			backgroundColor: ''
			
		icon = options.icon
		type = options.type
		placeHolder = options.placeHolder
		placeHolderFocus = options.placeHolderFocus
		pattern = options.pattern
			
		# icon 
		@icon = new Layer _.extend icon,
			parent: @
		
		# line
		@line = new Layer
			y: @icon.maxY + 25
			width: @width, height: 5
			opacity: .5
			backgroundColor: 'white'
			parent: @
		@line.states = 
			blur: 
				opacity: .5
				animationOptions: { time: .15 }
			focus: 
				opacity: 1
				animationOptions: { time: .15 }
				
		# input
		@input = new InputField
			x: @icon.maxX + 25
			width: @width - (@icon.maxX + 25), height: @icon.height
			type: type, indent: 0
			fontSize: 37, fontWeight: 400
			placeHolder: placeHolder, placeHolderFocus: placeHolderFocus, placeHolderColor: "rgba(255,255,255,.7)"
			autoCapitalize: false, autoComplete: false, autoCorrect: false
			pattern: pattern
			color: 'white'
			backgroundColor: ''
			parent: @
		
		@input.onInput (value) => @_value = value
		@input.onFocus => @line.stateSwitch 'focus'
		@input.onBlur => @line.animate 'blur'
		
		@size = @contentFrame()

	# Event Helper
	onInput: (cb) -> @input.onInput cb
	onFocus: (cb) -> @input.onFocus cb
	onBlur: (cb) -> @input.onBlur cb
	onValid: (cb) -> @input.onValid cb
	onInvalid: (cb) -> @input.onInvalid cb
	onMatch: (cb) -> @input.onMatch cb
	onFileDate: (cb) -> @input.onFileData cb