'''
Bowling Club : Home : Dashboard

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Dashboard extends Button

	Events.UserLoaded = 'userLoaded'

	@define 'data', @simpleProperty('data', null)

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			height: 497
			shadowY: 1, shadowColor: 'rgba(0,0,0,.2)'
			backgroundColor: 'white'

		@photo = new Photo
			x: 28, y: 15
			size: 70
			borderRadius: 35, borderColor: R.color.grey_979797, borderWidth: 1
			parent: @
			
		@displayName = new TextLayer
			x: @photo.maxX + 11, y: @photo.y + 4
			html: '-'
			fontSize: 35, fontWeight: 400
			letterSpacing: -1.2
			truncate: true
			color: R.color.grey_4a4a4a
			parent: @
		@displayName.width = @width - @displayName.x
		
		@email = new TextLayer
			x: @photo.maxX + 12, y: @displayName.maxY - 4
			html: '-'
			fontSize: 20, fontWeight: 400
			color: R.color.grey_4a4a4a
			parent: @
		@email.width = @width - @email.x
		
		@date = new TextLayer
			x: Align.right(-35), y: 52
			html: '-'
			height: 35
			fontSize: 30, fontWeight: 400
			letterSpacing: -1.2
			textAlign: 'right'
			color: R.color.grey_4a4a4a
			parent: @
		
		line = new Layer
			x: Align.center(-9), y: Align.center
			width: 2, height: 150
			backgroundColor: R.color.grey_696969
			rotation: 15
			parent: @
		
		@avarage = new TextLayer
			x: 39, y: @photo.maxY + 30
			html: 'Average'
			fontSize: 40, fontWeight: 400
			letterSpacing: -2
			color: R.color.grey_4a4a4a
			parent: @
		
		@avarage.value = new TextLayer
			x: 0, y: @avarage.maxY - 15
			html: '-'
			width: (@width / 2) - 64
			fontSize: 140, fontWeight: 400
			letterSpacing: -10
			textAlign: 'right'
			color: R.color.grey_4a4a4a
			parent: @
		
		@games = new TextLayer
			x: 40, y: @avarage.maxY + 136
			html: '-'
			fontSize: 70, fontWeight: 400
			letterSpacing: -5
			color: R.color.grey_4a4a4a
			parent: @
		@games.label = new TextLayer
			x: @games.maxX + 5, y: @games.maxY - 48
			html: 'Game'
			fontSize: 30, fontWeight: 400
			color: R.color.grey_4a4a4a
			parent: @
		@games.onChange 'html', =>
			Util.text.autoSize @games
			@games.label.x = @games.maxX + 10
			
		@scorePrev = new TextLayer
			x: Align.right(-59), y: 109
			html: '-'
			width: @width / 2 - 9 - 64
			fontSize: 140, fontWeight: 400
			letterSpacing: -10
			textAlign: 'right'
			color: R.color.grey_f1f1f1
			parent: @
		@scorePrev.label = new TextLayer
			x: @scorePrev.width, y: Align.bottom(-35)
			html: '점'
			fontSize: 30, fontWeight: 400
			color: R.color.grey_f1f1f1
			parent: @scorePrev
			
		@score = new TextLayer
			x: Align.right(-26), y: @scorePrev.maxY + 50
			html: '점'
			fontSize: 30, fontWeight: 400
			color: R.color.grey_4a4a4a
			parent: @
			
		@score.value = new TextLayer
			x: Align.right(-50), y: Align.bottom(40)
			html: '-'
			fontSize: 150, fontWeight: 400
			letterSpacing: -10
			textAlign: 'right'
			color: R.color.grey_4a4a4a
			parent: @score
			
		@scoreRising = new TextLayer
			x: @score.x - 50, y: @score.maxY - 42
			width: 100
			html: '-'
			fontSize: 40, fontWeight: 400
			textAlign: 'right'
			letterSpacing: -1.3
			color: R.color.grey_4a4a4a
			visible: false
			parent: @
			
		@bar = new Layer
			y: Align.bottom(-90)
			width: @width, height: 5
			backgroundColor: R.color.grey_f1f1f1
			parent: @
			
		@bar.progress = new Layer
			x: Align.right
			width: 0, height: @bar.height
			parent: @bar
			backgroundColor: R.color.grey_4a4a4a
			
		@like = new Layer _.extend R.drawable.like,
			x: 31, y: @bar.maxY + 23
			parent: @
			
		@likeCount = new TextLayer
			x: @like.maxX + 21, y: @like.y
			html: '좋아요'
			fontSize: 30, fontWeight: 400
			letterSpacing: -2
			color: R.color.grey_4a4a4a
			parent: @
			
		@likeUsers = new Layer
			x: Align.right(-26), y: @bar.maxY + 14
			height: 56
			backgroundColor: ''
			parent: @

		@l = new Layer
			x: 0, y: @bar.maxY
			width: @width, height: @height - @bar.maxY
			backgroundColor: ''
			parent: @

		# Action ------------------------------------------------------------ /

		@score.value.onChange 'html', =>
			Util.text.autoSize @score.value
			@score.value.x = -@score.value.width - 20
			@scoreRising.maxX = @score.x - @score.value.width - 30
			
			@bar.progress.width = parseInt(@score.value.html) / 300 * @bar.width
			@bar.progress.x = Align.right
		@score.value.html = '-'

		@scoreRising.onChange 'html', -> @visible = @html != '0'

		@date.onChange 'html', -> 
			Util.text.autoSize @
			@x = Align.right(-35)
			@width += 5

		# 좋아요
		@l.onTouchStart (e) => e.stopPropagation()
		@l.onTouchMove (e) => e.stopPropagation()
		@l.onTouchEnd (e) => e.stopPropagation()
		@l.onClick (e) =>	e.stopPropagation(); @_reqLike()

		#
		@bind(@data) if @data
		
	# 데이터 바인딩		
	bind: (data) ->
		@data = data
		
		# 프로필 : 요청
		if data?.user? then @bindUser data.user
		else @_reqGetUser()
		
		# 시간
		@date.html = Util.date.fromNow data.updateAt
		
		# 에버러지
		@avarage.value.html = data.average.current
		# 게임
		@games.html = data.games
		
		# 점수
		@scorePrev.html = data.score.previous
		@score.value.html = data.score.current
		 
		# 점수 : 상승
		if data.score.rising > 0
			@scoreRising.html = "+#{data.score.rising}"
			@scoreRising.color = R.color.score_up
			@bar.progress.backgroundColor = R.color.score_up
		# 점수 : 하락
		else if data.score.rising < 0
			@scoreRising.html = "#{data.score.rising}"
			@scoreRising.color = R.color.score_down
			@bar.progress.backgroundColor = R.color.score_down

		# 좋아요 + 좋아요한 사람
		@bindLikes(data)

	# 데이터 바인딩 : 사용자
	bindUser: (data) ->
		@data.user = data
		@emit Events.UserLoaded, @data.uid, @data

		if data
			# @photo.setImage data.photoThumbUrl unless _.isEmpty data.photoThumbUrl
			if data.photoThumbUrl?
				if data.photoThumbUrl == true
					Firebase.get().downloadThumbURL(@data.uid)
					.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)
				else if data.photoThumbUrl == false
					console.log 'photoThumbUrl is false'
				else 
					@photo.setImage data.photoThumbUrl

			@displayName.html = data.displayName
			@email.html = data.email

	# 데이터 바인딩 : 좋아요
	bindLikes: (data) ->
		# 좋아요
		@likeCount.html = "좋아요 #{data.likes}개" if data.likes > 0
		Util.text.autoSize @likeCount

		# 좋아요한 사람
		if data.likeUsers and !_.isNull data.likeUsers and !_.isEmpty data.likeUsers
			child.destroy() for child in @likeUsers.children
			x = 0; i = 0
			for userId of data.likeUsers
				likeUser = new LikeUser
					x: Align.right(x)
					userId: userId
					parent: @likeUsers
				likeUser.sendToBack()
				x -= 32
				
				# more
				if i >= 4
					more = new Layer _.extend R.drawable.more,
						x: Align.right(x - 35), y: Align.center
						parent: @likeUsers
					break

				i++

	# request user info
	_reqGetUser: ->
		Firebase.get().onceGetUser(@data.uid)
		.then (snapshot) => @bindUser(snapshot.val())
		.catch (error) -> alert error

	# request like
	_reqLike: ->
		unless Firebase.get().user.uid == @data.uid
			Firebase.get().updateLike(@data.uid)
			.then (committed, snapshot) =>
				@bindLikes(snapshot.val()) if committed and snapshot.val() and !_.isUndefined snapshot.val() and !_.isNull snapshot.val()
			# .catch (error) -> alert error
		else alert '자작하기 없기~ ^^v'


	# Event Helper
	onUserLoaded: (cb) -> @on Events.UserLoaded, cb

'''
Bowling Club : Home : Dashboard : Like : User
'''		
class LikeUser extends Photo
	@define 'userId', @simpleProperty('userId', null)

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			size: 56
			borderRadius: 28, borderWidth: 3, borderColor: 'white'
			backgroundColor: 'lightgrey'

		#
		@_reqGetUser()

	bindUser: (snapshot) ->
		data = snapshot.val()

		if data
			# @setImage data.photoThumbUrl unless _.isEmpty data.photoThumbUrl
			# if data.photoThumbUrl?
			# 	Firebase.get().downloadThumbURL(@userId)
			# 	.then (url) => @setImage url unless _.isNull(url) and _.isEmpty(url)

			if data.photoThumbUrl?
				if data.photoThumbUrl == true
					Firebase.get().downloadThumbURL(@userId)
					.then (url) => @setImage url unless _.isNull(url) and _.isEmpty(url)
				else if data.photoThumbUrl == false
					console.log 'photoThumbUrl is false'
				else 
					@setImage data.photoThumbUrl

	# request user info
	_reqGetUser: ->
		Firebase.get().onceGetUser(@userId)
		.then (snapshot) => @bindUser(snapshot)
		.catch (error) -> alert error