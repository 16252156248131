'''
Widget : Button

@auther Jungho song (dev@threeword.com)
@since 2017.05.03
'''
class exports.Button extends Layer
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'btn'
			backgroundColor: 'white'

		@normal = @backgroundColor
		@pressed = options.pressed ?= 'rgba(200,200,200,.1)'

		#
		@onTouchStart ->
			@backgroundColor = @pressed

		@onTouchMove ->
			@backgroundColor = @normal

		@onTouchEnd ->
			@backgroundColor = @pressed
			@animate backgroundColor: @normal, options: time: .15

	setNormal: (color) -> @normal = color
