'''
Bowling Club : Sign Up

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.SignUp extends BaseLayer
	constructor: (options = {}) ->
		super _.defaults options,
			name: 'singup'
			backgroundColor: 'black'
			
	create: ->
		super
			
		wrap = new Layer width: 570, backgroundColor: ''
		wrap.label = new TextLayer
			x: Align.right
			width: 410
			html: 'Sign Up'
			fontSize: 120
			fontWeight: 500
			letterSpacing: -2.2
			color: 'white'
			parent: wrap
		
		wrap.bar = new Layer
			x: 95, y: wrap.label.maxY + 47
			width: 280, height: 10
			backgroundColor: 'white'
			parent: wrap
		
		# photo
		wrap.photo = new Preview
			x: Align.center, y: wrap.bar.maxY + 68
			size: 228
			borderRadius: 114, borderColor: 'white', borderWidth: 3
			backgroundColor: 'rgba(0,0,0,.3)'
			parent: wrap
		wrap.camera = new Layer
			maxX: wrap.photo.maxX, maxY: wrap.photo.maxY
			size: 64
			borderRadius: 32, borderColor: 'black', borderWidth: 3
			backgroundColor: R.color.accentColor
			parent: wrap
		wrap.camera.icon = new Layer _.extend R.drawable.camera,
			point: Align.center
			parent: wrap.camera
		wrap.camera.file = new InputPhoto
			point: Align.center
			size: wrap.camera.size
			parent: wrap.camera
		
		# username 
		wrap.username = new Input
			y: wrap.photo.maxY + 50
			icon: R.drawable.person
			type: "text"
			placeHolder: "Username", placeHolderFocus: "이름"
			pattern: ""
			parent: wrap
			
		# email
		wrap.email = new Input
			y: wrap.username.maxY + 38
			icon: R.drawable.email
			type: "email"
			placeHolder: "Email", placeHolderFocus: "이메일"
			pattern: ""
			parent: wrap
		
		# password
		wrap.password = new Input
			y: wrap.email.maxY + 38
			icon: R.drawable.lock
			type: "password"
			placeHolder: "Password", placeHolderFocus: "6 자리 이상"
			pattern: ""
			parent: wrap
			
		# confirm password
		wrap.confirmPassword = new Input
			y: wrap.password.maxY + 38
			icon: R.drawable.lock
			type: "password"
			placeHolder: "Confirm Password", placeHolderFocus: "비밀번호 확인"
			pattern: ""
			parent: wrap
			
		wrap.signup = new Button
			y: wrap.confirmPassword.maxY + 62
			width: 570, height: 100
			borderColor: R.color.accentColor, borderWidth: 5
			pressed: R.color.accentColor
			backgroundColor: ''
			parent: wrap
		wrap.signup.bar = new Layer
			x: 35, y: Align.center
			width: 240, height: 10
			backgroundColor: 'white'
			parent: wrap.signup
		wrap.signup.label = new TextLayer
			x: Align.right(-30), y: Align.center
			width: 230, height: 40
			html: 'SIGN UP NOW'
			fontSize: 35
			fontWeight: 500
			textAlign: 'center'
			color: 'white'
			backgroundColor: ''
			parent: wrap.signup

		wrap.height = wrap.contentFrame().height + 40
		wrap.x = (@width - wrap.width) / 2
		wrap.y = 36
			
		@setContent(wrap)


		# Action ------------------------------------------------------------------------ //

		# file load
		wrap.camera.file.onPreview (canvas, dataURI, inputPhoto, file) ->
			EXIF.getData file, -> wrap.photo.setImage dataURI, file, EXIF.getAllTags(this)

		# Sign Up
		wrap.signup.onClick =>
			username = wrap.username.value
			email = wrap.email.value
			password = wrap.password.value
			confirmPassword = wrap.confirmPassword.value

			if _.isEmpty username then alert '이름을 입력해 주세요.'
			else if _.isEmpty email then alert '이메일을 입력해 주세요.'
			else if !Util.valid.email(email) then alert '이메일이 올바르지 않아요.'
			else if _.isEmpty password then alert '비밀번호를 입력해 주세요.'	
			else if password.length < 6 then alert '비밀번호를 6자리 이상 입력해 주세요.'	
			else if _.isEmpty confirmPassword then alert '재확인 비밀번호을 입력해 주세요.'
			else if password != confirmPassword then alert '비밀번호가 올바르지 않아요.'
			# 
			else @_reqCreateUserToAuth username, email, password, wrap.photo
				

	# request : create user to auth
	_reqCreateUserToAuth: (displayName, email, password, photoLayer) ->
		Firebase.get().showLoading()
		Firebase.get().createUserWithEmailAndPassword(email, password, displayName)
		.then (snapshot) =>
			console.debug snapshot.val()
			 
			# 사진 있으면 업로드하고 계정 정보 생성
			if photoLayer?.originalFile 
				console.log '사진있음'
				@_reqUploadPhoto photoLayer
			# 사진 없으면 게정 정보 생성
			else 
				console.log '사진없음'
				@_goToClubs()
		.catch (error) -> alert(error);

	# request : upload photo to storage
	_reqUploadPhoto: (photoLayer) ->
		photoLayer?.getImage (file, blob, exif) =>
			Firebase.get().uploadProfilePhoto(file, blob, exif)
			.then (snapshot) => @_goToClubs()
			.catch (error) -> alert error

	# goto : club list
	_goToClubs: ->
		Firebase.get().dismissLoading()
		clubs = new Clubs()
		clubs.create()
		BowlingClub.get().fc.showNext clubs, scroll: false
		BowlingClub.get().fc.onTransitionStop => @destroy()