keySize = 128
iterations = iterationCount = 10000

iv = "F27D5C9927726BCEFE7510B1BDD3D137"
salt = "3FF2EC019C627B945225DEBAD71A01B6985FE84C95A70EB132882F88C0A59A55"
passPhrase = "passPhrase passPhrase aes encoding algorithm"

aesUtil = new AesUtil(keySize, iterationCount)

# 보안
Security = {}
# 암호화
Security.encrypt = (plainText) -> aesUtil.encrypt(salt, iv, passPhrase, plainText)
# 복호화
Security.decrypt = (encrypt) -> aesUtil.decrypt(salt, iv, passPhrase, encrypt)

module.exports = Security if module?
Framer.Security = Security