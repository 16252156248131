'''
Bowling Club : Input Photo

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.InputPhoto extends Layer

	Events.Change = "change"
	Events.Preview = "preview"
	Events.Load = "load"

	@define 'value', @simpleProperty('value', null)
	@define 'isLoaded', @simpleProperty('isLoaded', false)

	# 생성자
	constructor: (options = {}) ->
		options.backgroundColor ?= "transparent"
		super options

		@preview = options.preview

		# file = undefined
		# metadata = undefined

		input = document.createElement "input"
		input.name = ".file"
		input.type = "file"
		input.accept = "image/*"
		# 파일 : 스타일
		_.extend input.style,
			font: "400 #{@height}px/#{@height}px #{Utils.deviceFont()}"
			# outline: "none"
			backgroundColor: "transparent"
			height: "100%"
			width:  "100%"
			# pointerEvents: "none"
			cursor: "pointer"
			margin: "0"
			padding: "0"
			borderRadius: "100%"
			opacity: "0"
			"-webkit-appearance": "none"
		@_element.appendChild input

		# 파일 : 이벤트
		Events.wrap(input).addEventListener "change", (event) =>
			event.stopPropagation()
			event.preventDefault()
			
			@value = event.target.files[0]
			# metadata = 'contentType': file.type

			# 이벤트 : 변경
			@emit Events.Change, @value, @
			
			reader = new FileReader()
			reader.readAsDataURL(@value)
			
			# 프리뷰
			reader.onload = (e) =>
				@emit Events.Load, e.target.result
				@isLoaded = true

				tempImage = new Image()
				tempImage.src = reader.result 
				tempImage.onload = =>
					canvas = document.createElement('canvas')
					canvasContext = canvas.getContext("2d")
					
					canvas.width = tempImage.width
					canvas.height = tempImage.height
					
					canvasContext.drawImage(tempImage, 0, 0, tempImage.width, tempImage.height)
					
					dataURI = canvas.toDataURL("image/jpeg")

					# 프리뷰
					if @preview
						@preview.html = ''
						@preview.image = dataURI

					# 이벤트 : 프리뷰
					@emit Events.Preview, canvas, dataURI, @, @value, e.target.result

	clear: -> 
		@value = null
		@isLoaded = false

		if @preview
			@preview.html = ''
			@preview.image = ''


	# EVENT HELPER
	onChange: (cb) -> @on Events.Change, cb
	onLoad: (cb) -> @on Events.Load, cb
	onPreview: (cb) -> @on Events.Preview, cb