'''
Widget : Checkbox

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Checkbox extends Layer
	@define 'checked', 
		get: -> @_checked
		set: (value) -> 
			@_checked = value
			if @checkbox?.check
				@checkbox.check.opacity = if @_checked then 1 else 0 
	
	constructor: (options = {}) ->
		super _.defaults options,
			backgroundColor: ''

		text = options.text
		fontSize = options.fontSize ?= 40
		fontWeight = options.fontWeight ?= 400
		checkedColor = options.checkedColor ?= 'black'
		drawablePadding = options.drawablePadding ?= 10

		@checkbox = new Layer
			size: @height
			borderColor: @borderColor, borderWidth: 5
			backgroundColor: ''
			parent: @
			
		@checkbox.check = new Layer
			x: Align.center(0), y: Align.center(-2)
			width: @height/2, height: @height/2 - 10
			style:
				borderStyle: 'hidden hidden solid solid'
			borderColor: checkedColor, borderWidth: @checkbox.borderWidth - 1
			rotation: -45
			backgroundColor: ''
			opacity: 0
			parent: @checkbox

		@labelText = new TextLayer
			x: @checkbox.maxX + drawablePadding, y: Align.bottom
			width: @width - @checkbox.width + drawablePadding, 
			html: text
			color: @color
			fontSize: fontSize, fontWeight: fontWeight
			letterSpacing: -1
			lineHeight: 1
			parent: @
		
		@onClick -> 
			if @checked
				@checked = false
				@checkbox.check.animate opacity: 0, options: curve: 'spring(200,15,0)'
			else
				@checked = true
				@checkbox.check.animate opacity: 1, options: curve: 'spring(200,15,0)' 

			@checkbox.scale = .9
			@checkbox.animate scale: 1, options: curve: 'spring(200,15,0)'
