'''
Widget : Preivew

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Preview extends Layer
	@define 'exif', @simpleProperty('exif', null)
	@define 'originalFile', @simpleProperty('originalFile', null)
	@define 'thumbBlob', @simpleProperty('thumbBlob', null)
	@define 'thumbSize', @simpleProperty('thumbSize', 228)

	constructor: (options = {}) ->
		super _.defaults options,
			clip: true
			style: imageOrientation: 'from-image'
			backgroundColor: '#f5f5f5'

		@crop = new Layer
			point: Align.center
			size: @thumbSize
			opacity: 0
			backgroundColor: ''
			parent: @

		@placeholder = new TextLayer
			point: Align.center
			html: "B"
			fontSize: Math.min(@width, @height)/2.5, fontWeight: 500
			textAlign: 'center'
			color: 'lightgrey'
			parent: @

		@img = new Layer
			size: @size
			backgroundColor: @backgroundColor
			opacity: 0
			parent: @
		
		@croppie = new Croppie @crop._element,
			enableExif: false
			enableOrientation: true
			mouseWheelZoom: false
			showZoomer: false
			# boundary: @crop.size
			viewport: @crop.size

		@img.on Events.ImageLoaded, @onLoaded
		@img.on 'load', @onLoaded

	onLoaded: =>
		# console.log @exif
		if @exif
			orientation = @exif.Orientation
			if orientation == 8 then @img.rotation = 270
			else if orientation == 6 then @img.rotation = 90
			else if orientation == 3 then @img.rotation = 180
			else @img.rotation = 0
		@img.animate opacity: 1

		#
		@croppie.bind url: @result if @result

	setImage: (dataURI, file, exif, result) ->
		#
		@originalFile = file if file?
		@exif = exif if exif?

		@result = result if result?

		#
		@img.opacity = 0
		@img.image = dataURI

	getImage: (callback) ->
		@croppie.result(type: 'blob', format: 'jpeg', quality: .6, size: @crop.size)
		.then (blob) => 
			@thumbBlob = blob
			callback @originalFile, @thumbBlob, @exif if callback