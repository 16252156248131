'''
Bowling Club : Kit

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''

# # hide error display
# Framer.Extras.ErrorDisplay.disable()

# # hint disable
# Framer.Extras.Hints.disable()

# # preloader
# Framer.Extras.Preloader.disable()
# Framer.Extras.Preloader.setLogo("https://twitter.com/framerjs/profile_image?size=bigger")

# font
# Utils.insertCSS '@import url(fonts/kakaoOTF.css);'
# Utils.insertCSS "body, table, div, p {font-family:Kakao, #{Utils.deviceFont()}}"
# Utils.insertCSS "body, table, div, p {#{Utils.deviceFont()}}"

# moment : language : kr
# moment.lang('kr')
	
# moment : locale : ko
moment.locale('ko')

# BowlingClub
BowlingClubKit = {}

# Utils
BowlingClubKit.Util = (require 'util')
BowlingClubKit.Cookie = (require 'cookie')
BowlingClubKit.Security = (require 'security')
_.extend(window, BowlingClubKit) if window

# Resource
BowlingClubKit.R = (require 'bc-resource')
_.extend(window, BowlingClubKit) if window

# Devices
BowlingClubKit.Device = new (require 'bc-device').Device
_.extend(window, BowlingClubKit) if window

# Preference
BowlingClubKit.Preference = (require 'bc-preference')
BowlingClubKit.Dto = (require 'bc-dto')
_.extend(window, BowlingClubKit) if window

#Firebase
BowlingClubKit.Firebase = (require 'bc-firebase')
_.extend(window, BowlingClubKit) if window

# Module
BowlingClubKit.InputField = (require 'input-field').InputField
BowlingClubKit.InputPhoto = (require 'input-photo').InputPhoto

# Widget
BowlingClubKit.Button = (require 'widget-button').Button
BowlingClubKit.Loading = (require 'widget-loading').Loading
BowlingClubKit.Checkbox = (require 'widget-checkbox').Checkbox
BowlingClubKit.Photo = (require 'widget-photo').Photo
BowlingClubKit.Preview = (require 'widget-preview').Preview
BowlingClubKit.AppBar = (require 'widget-appbar').AppBar
BowlingClubKit.Input = (require 'widget-input').Input
BowlingClubKit.Copyright = (require 'widget-copyright').Copyright
_.extend(window, BowlingClubKit) if window

# Package
BowlingClubKit.BowlingClub = (require 'bc')
BowlingClubKit.BowlingClub.get()
# _.extend(window, BowlingClubKit) if window

BowlingClubKit.BaseLayer = (require 'bc-base-layer').BaseLayer
_.extend(window, BowlingClubKit) if window

BowlingClubKit.Login = (require 'bc-login').Login
BowlingClubKit.SignUp = (require 'bc-signup').SignUp
BowlingClubKit.Club = (require 'bc-club').Club
BowlingClubKit.Clubs = (require 'bc-clubs').Clubs
BowlingClubKit.AddClub = (require 'bc-club-add').AddClub
BowlingClubKit.NewClub = (require 'bc-club-new').NewClub
BowlingClubKit.Notification = (require 'bc-notification').Notification
BowlingClubKit.Score = (require 'bc-score').Score
BowlingClubKit.ProfileClubs = (require 'bc-profile-clubs').ProfileClubs
BowlingClubKit.Graph = (require 'bc-profile-graph').Graph
BowlingClubKit.Game = (require 'bc-profile-game').Game
BowlingClubKit.GameHeader = (require 'bc-profile-game-header').GameHeader
BowlingClubKit.Profile = (require 'bc-profile').Profile
BowlingClubKit.Rank = (require 'bc-home-rank').Rank
BowlingClubKit.Dashboard = (require 'bc-home-dashboard').Dashboard
BowlingClubKit.LeaderBoard = (require 'bc-leaderboard').LeaderBoard
BowlingClubKit.Home = (require 'bc-home').Home

#
_.extend(window, BowlingClubKit) if window
window.BowlingClubKit = BowlingClubKit if window