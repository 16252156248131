'''
Bowling Club : My : Game : Header

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''		
class exports.GameHeader extends Layer

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.stickyHeader'
			height: 60
			backgroundColor: 'white'

		@isFirst = options.isFirst
		@value = options.value
		@date = @value[0]
		@games = @value[1]
		@average = Utils.roundWhole _.sumBy(@games, Dto.game.score) / @games.length

		@line = new Layer
			x: 27, y: if @isFirst then @height/2 - 2 else - 2
			width: 3, height: if @isFirst then @height/2 + 4 else @height + 4
			backgroundColor: R.color.grey_d8d8d8
			parent: @

		@day = new Layer
			y: Align.center
			width: @width, height: @height
			backgroundColor: ''
			parent: @
		@day.dot = new Layer
			midX: @line.midX, y: Align.center
			size: 33
			borderRadius: 17, borderColor: 'white', borderWidth: 10
			backgroundColor: R.color.grey_7c7c7c
			parent: @day
		@day.text = new TextLayer
			x: @day.dot.maxX + 6, y: Align.center
			width: @width - (@day.dot.maxX + 6)
			html: "#{@date} - #{@average}<span style='font-size:18px;'> 점</span> / #{@games.length}<span style='font-size:18px;'> 게임</span>"
			fontSize: 35, fontWeight: 500
			letterSpacing: -2
			color: R.color.grey_4a4a4a
			parent: @day

		# @bind(@data) if @data

	# # 데이터 바인딩
	# bind: (data) ->
	# 	@data = data

	# 	date = moment(data.updateAt)
	# 	@day.text.html = date.format('YYYY년 MM월 DD일 (dddd)')