'''
Widget : Photo

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Photo extends Layer
	@define 'exif', @simpleProperty('exif', null)

	constructor: (options = {}) ->
		super _.defaults options,
			clip: true
			style: imageOrientation: 'from-image'
			backgroundColor: '#f5f5f5'

		@dataURI = null

		@placeholder = new TextLayer
			point: Align.center
			html: "B"
			fontSize: Math.min(@width, @height)/2.5, fontWeight: 500
			textAlign: 'center'
			color: 'lightgrey'
			parent: @

		@img = new Layer
			size: @size
			backgroundColor: @backgroundColor
			opacity: 0
			parent: @
		@img.onImageLoaded @onLoaded
		@img.on 'load', @onLoaded

	onLoaded: =>
		orientation = 1
		if @exif
			orientation = @exif.Orientation
			if orientation == 8 then @img.rotation = 270
			else if orientation == 6 then @img.rotation = 90
			else if orientation == 3 then @img.rotation = 180
			else @img.rotation = 0

		@img.animateStop()
		@img.animate opacity: 1, options: delay: .5

	setImage: (dataURI, exif) ->
		unless @dataURI or @dataURI == dataURI

			@img.animateStop()
			@img.opacity = 0

			@exif = JSON.parse(exif) if exif?
			@img.image = dataURI

			@dataURI = dataURI

	clear: ->
		@img.animateStop()
		@img.opacity = 0
