'''
Bowling Club : BaseLayer

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.BaseLayer extends Layer
	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			width: Device.width
			height: Screen.height / Device.ratio
			# scale: Device.ratio, originX: 0, originY: 0

		@isFullscreen = window.navigator.standalone or Utils.isDesktop()

		# bg
		@bg = new Layer
			name: '.'
			size: @size
			grayscale: 100
			saturate: 100
			visible: false
			parent: @
		@dim = new Layer
			name: '.'
			size: @size
			style:
				background: "-webkit-linear-gradient(top, rgba(5,5,5,.91) 0%, rgba(0,0,0,1) 100%)"
			opacity: .8
			visible: false
			backgroundColor: ""
			parent: @

		# content
		@content = new ScrollComponent
			width: @width, height: @height
			scrollHorizontal: false
			scrollVertical: false
			mouseWheelEnabled: true
			backgroundColor: ""
			parent: @
		@content.content.backgroundColor = ''
		@content.contentInset = { top: 40 } if @isFullscreen

		# copyright
		@copyright = new Copyright parent: @
		@copyright.y = @height - @copyright.height

		# status
		@showStatus()

	destroy: ->
		child.destroy() for child in @descendants
		super

	create: (data) ->
		@setBg()

	# 상태바 노출 여부
	showStatus: ->
		if Utils.isDesktop() && !@statusbar? 
			@statusbar = new Layer 
				name: 'statusbar'
				width: Device.width, height: 40
				image: "images/statusbar.png"
				invert: 100
				parent: @

	# 배경
	setBg: (image = Utils.randomImage(), isDim = true) ->
		@bg.props = visible: true, image: image
		@dim.props = visible: isDim

	# 콘텐츠
	setContent: (content) ->
		@content.content.addChild content
		content.onChange 'height', onChangeHeight = =>
			@content.updateContent()

			if content.y + content.height > @content.height - @content.contentInset.top
				@content.scrollVertical = true 

				@copyright.props = 
					y: @content.content.height
					parent: @content.content

			else 
				@copyright.props = 
					y: @height - @copyright.height
					parent: @

			@content.updateContent()

		#
		onChangeHeight()

	# 앱바
	setAppBar: (appbar) ->
		@addChild appbar
		@statusbar.bringToFront() if @statusbar?