'''
Bowling Club : Club

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Club extends Layer
	Events.WaitDone = 'waitDone'

	@define 'clubId', @simpleProperty('clubId', null)
	@define 'data', @simpleProperty('data', null)

	constructor: (options = {}) ->
		super _.defaults options,
			width: 680, height: 250
			borderRadius: 10
			clip: true
			backgroundColor: 'white'

		@clubId = options.clubId
		@data = options.data

		# console.debug @clubId
		# console.debug @data

		@photo = new Photo
			size: 250
			backgroundColor: ''
			clip: true
			parent: @
			
		unless _.isEmpty(@data.coverUrl)
			Util.graphic.loadImage @photo, @data.coverUrl, (layer, e) =>
				image = e.target

				ratio = 1
				if image.width > image.height
					ratio = layer.height / image.height
				else 
					ratio = layer.width / image.width

				layer.url = new Layer 
					point: Align.center
					width: image.width, height: image.height
					image: image.src
					scale: ratio, originX: .5, originY: .5
					opacity: 0
					parent: layer

				layer.url.once Events.AnimationStart, => layer.onLoaded()
				layer.url.animate opacity: 1, options: delay: .5
				
			, (error) -> console.log error

		@label = new TextLayer
			x: @photo.maxX + 20, y: 24
			width: 390, height: 47
			html: @data.name
			textOverflow: "ellipsis", truncate: true
			fontSize: 40, fontWeight: 500
			letterSpacing: -1.3
			color: R.color.grey_4a4a4a
			parent: @
			
		@description = new TextLayer
			width: 390, height: 89
			x: @label.x, y: @label.maxY + 17
			html: @data.brief
			textOverflow: "ellipsis", truncate: true
			fontSize: 25, fontWeight: 400
			letterSpacing: -0.8, lineHeight: 1.2
			color: R.color.grey_696969
			parent: @
			
		@master = new TextLayer
			x: @label.x, y: Align.bottom(-17)
			# html: "회장 : #{@data.master}"
			html: '회장 :'
			fontSize: 25, fontWeight: 500
			letterSpacing: -0.8
			color: R.color.grey_525252
			parent: @
		
		memberCount = 0
		memberCount = _.size(@data.members) if @data.members
		@members = new TextLayer
			x: Align.right(-20), y: Align.bottom(-17)
			html: "회원수 : #{memberCount}명"
			fontSize: 25, fontWeight: 500
			letterSpacing: -0.8
			color: R.color.grey_525252
			parent: @

		unless @data.allow
			new TextLayer
				point: Align.center()
				html: "승인 요청 중<br/><span style='font-size:50%;font-weight:400;'>승인된 클럽만 가입할 수 있습니다.</span>"
				fontSize: 60, fontWeight: 500
				textAlign: 'center'
				lineHeight: .8
				color: 'white'
				parent: 
					@allow = new Layer 
						width: @width, height: @height
						borderRadius: 10
						backgroundColor: 'rgba(0,0,0,.6)'
						parent: @
		
		# Action ----------------------------------------------------- /

		# select
		@onClick =>
			clubIds = Firebase.get().clubIds
			if clubIds and !_.isEmpty clubIds
				if _.indexOf(clubIds, @clubId) != -1
					alert '이미 가입한 클럽입니다.'
					return;

			isWait = confirm "\"#{@label.html}\"에 가입하시겠습니까?"
			@_reqWaitClub @clubId if isWait

		#
		@_reqMasterInfo @master, @data.master


	bindMasterInfo: (layer, snapshot) =>
		return if _.isNull snapshot.val()
		user = snapshot.val()
		layer.html = "회장 : #{user.displayName}"
		Util.text.autoSize layer

	# request: master info
	_reqMasterInfo: (layer, userId) ->
		Firebase.get().onceGetUser(userId)
		.then (snapshot) => @bindMasterInfo(layer, snapshot)
		.catch (error) -> alert error

	# request : enter club		
	_reqWaitClub: (clubId) ->
		Firebase.get().waitClub(clubId)
		.then () => @emit Events.WaitDone, clubId, @	
		.catch (error) -> alert error

	# Events Helper
	onWaitDone: (cb) -> @on Events.WaitDone, cb