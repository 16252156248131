'''
Bowling Club : Home : Rank

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''
class exports.Rank extends Button
#	@define 'data', @simpleProperty('data', null)
#	@define 'key', @simpleProperty('key', null)
#	@define 'extraKey', @simpleProperty('extraKey', null)

	# 생성자
	constructor: (options = {}) ->
		super _.defaults options,
			name: '.'
			backgroundColor: 'white'
			
		title = options.title
		titleColor = options.titleColor
		valueSize = options.valueSize

		@data = null
		@key = null
		@extraKey = null
		
		@oFrame = @frame
		@tFrame = options.transformFrame

		@more = new TextLayer
			x: Align.right(-10), y: 7
			html: 'more'
			fontSize: 20, fontWeight: 500
			letterSpacing: -1.3
			color: 'white'
			textAlign: 'center'
			borderRadius: 3
			padding: left: 7, right: 7, bottom: 2
			backgroundColor: titleColor
			parent: @
		
		@title = new TextLayer
			x: 17, y: 24
			html: title
			fontSize: 40, fontWeight: 500
			letterSpacing: -1.3
			color: titleColor
			parent: @
			
		@photo = new Photo
			x: 17, y: Align.bottom(-19)
			size: 70
			borderRadius: 35, borderColor: R.color.grey_979797, borderWidth: 1
			parent: @
			
		@displayName = new TextLayer
			x: @photo.maxX + 11, y: @photo.y + 4
			html: '-'
			fontSize: 35, fontWeight: 400
			letterSpacing: -1.2
			truncate: true
			color: R.color.grey_4a4a4a
			parent: @

		@email = new TextLayer
			x: @photo.maxX + 12, y: @displayName.maxY - 4
			html: '-'
			fontSize: 20, fontWeight: 400
			color: R.color.grey_4a4a4a
			parent: @
		
		@value = new TextLayer
			x: Align.right(-30), y: Align.bottom(-70)
			width: @width - 30
			html: '-'
			fontSize: valueSize, fontWeight: 300
			letterSpacing: -12
			textAlign: 'right'
			color: R.color.grey_4a4a4a
			parent: @
			
		@extra = new TextLayer
			x: Align.right(-23), y: 70
			width: @width - 30
			html: '-'
			fontSize: 80, fontWeight: 300
			letterSpacing: -7
			textAlign: 'right'
			color: R.color.grey_4a4a4a
			visible: false
			parent: @
			
		@line = new Layer
			x: Align.right(-18), y: 208
			width: 0, height: 5
			backgroundColor: @borderColor
			parent: @
			
		@oPhotoF = @photo.frame
		@tPhotoF = x: 17, y: 224, width: 52, height: 52
		
		@oDisplayNameP = @displayName.point
		@tDisplayNameP = x: 78, y: 228
		
		@oEmailP = @email.point
		@tEmailP = x: 78, y: 228
		
		@oValueSize = valueSize
		@tValueSize = 120
		
		@oValueF = @value.frame
		@tValueF = x: 0, y: 76, width: @tFrame.width - 23, height: @tFrame.Height
		
		@oValueF = @value.frame
		@tValueF = x: 0, y: 76, width: @tFrame.width - 23, height: @tFrame.Height
		
		@oExtraF = @extra.frame
		@tExtraF = x: 0, y: 30, width: @tFrame.width - 23, height: @tFrame.Height
		
		@changeRatio = [0, 1]

		# Action ----------------------------------------- //
		@displayName.onChange 'html', -> Util.text.autoSize @; @width += 5
		@email.onChange 'html', -> Util.text.autoSize @; @width += 5
	
	# data binding
	bind: (data, key, extraKey) ->
		if _.get data, 'user'
			# profile
			# @photo.setImage @data.user.photoThumbUrl unless _.isEmpty @data.user.photoThumbUrl
			if data.user.photoThumbUrl?
				Firebase.get().downloadThumbURL(data.uid)
				.then (url) => @photo.setImage url unless _.isNull(url) and _.isEmpty(url)
			@displayName.html = data.user.displayName
			@email.html = data.user.email

		@data = data
		@key = key
		@extraKey = extraKey

		# value
		@value.html = _.get(@data, @key)
		
		# extra value
		if @extraKey?
			@extra.props =
				html: _.get(@data, @extraKey)
				visible: true

	# data update
	bindUpdate: (data) -> @bind(data, @key, @extraKey)
		
	#
	transform: (ratio) ->
		# size and position
		@frame = 
			x:  Utils.modulate ratio, @changeRatio, [@oFrame.x, @tFrame.x], true
			y: Utils.modulate ratio, @changeRatio, [@oFrame.y, @tFrame.y], true
			width: Utils.modulate ratio, @changeRatio, [@oFrame.width, @tFrame.width], true
			height: Utils.modulate ratio, @changeRatio, [@oFrame.height, @tFrame.height], true
		# shadow blur
		@shadowBlur = Utils.modulate ratio, @changeRatio, [5, 2], true
		
		# photo
		@photo.frame = 
			x:  Utils.modulate ratio, @changeRatio, [@oPhotoF.x, @tPhotoF.x], true
			y: Utils.modulate ratio, @changeRatio, [@oPhotoF.y, @tPhotoF.y], true
			width: Utils.modulate ratio, @changeRatio, [@oPhotoF.width, @tPhotoF.width], true
			height: Utils.modulate ratio, @changeRatio, [@oPhotoF.height, @tPhotoF.height], true
		# display name
		@displayName.point =
			x:  Utils.modulate ratio, @changeRatio, [@oDisplayNameP.x, @tDisplayNameP.x], true
			y: Utils.modulate ratio, @changeRatio, [@oDisplayNameP.y, @tDisplayNameP.y], true
		# email
		@email.point =
			x: Utils.modulate ratio, @changeRatio, [@oEmailP.x, @tEmailP.x], true
			y: Utils.modulate ratio, @changeRatio, [@oEmailP.y, @tEmailP.y], true  
		@email.opacity = 1 - ratio * 2
		
		# value
		@value.frame =
			x:  Utils.modulate ratio, @changeRatio, [@oValueF.x, @tValueF.x], true
			y: Utils.modulate ratio, @changeRatio, [@oValueF.y, @tValueF.y], true  
			width: Utils.modulate ratio, @changeRatio, [@oValueF.width, @tValueF.width], true
			height: Utils.modulate ratio, @changeRatio, [@oValueF.height, @tValueF.height], true
		@value.fontSize = Utils.modulate ratio, @changeRatio, [@oValueSize, @tValueSize], true
		@value.color = Color.mix R.color.grey_4a4a4a, @borderColor, ratio, true
		
		# extra
		@extra.frame =
			x:  Utils.modulate ratio, @changeRatio, [@oExtraF.x, @tExtraF.x], true
			y: Utils.modulate ratio, @changeRatio, [@oExtraF.y, @tExtraF.y], true  
			width: Utils.modulate ratio, @changeRatio, [@oExtraF.width, @tExtraF.width], true
			height: Utils.modulate ratio, @changeRatio, [@oExtraF.height, @tExtraF.height], true
		@extra.opacity = 1 - ratio * 2
		
		# line
		@line.props = 
			x: Utils.modulate ratio, [0.8, 1], [212, 112], true
			width: Utils.modulate ratio, [0.8, 1], [0, 100], true