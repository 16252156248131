'''
Bowling Club : My : Game

@auther Jungho song (dev@threeword.com)
@since 2017.04.01
'''		
class exports.Game extends Layer
	Events.Edit = 'edit'

	constructor: (options = {}) ->
		super _.defaults options,
			name: ''
			height: 195 #245
			backgroundColor: ''

		@data = options.data ?= null
		@todayGameCount = options.todayGameCount ?= 1
		@isEdit = options.isEdit ?= false

		line = new Layer
			x: 27, y: 2
			width: 3, height: @height
			backgroundColor: R.color.grey_d8d8d8
			parent: @

		@text = new TextLayer
			x: 48, y: 19
			width: @width - 48
			html: "<span style='font-weight:300;font-size:30px;'> - Game</span> - 점"
			fontSize: 45, fontWeight: 500
			letterSpacing: -1.46
			parent: @

		@resultPhoto = new Photo
			x: @text.x, y: @text.maxY
			width: 680, height: 122
			parent: @

		@edit = new TextLayer
			x: Align.right(-22), y: 33
			html: 'Edit'
			fontSize: 27, fontWeight: 300
			color: R.color.grey_5b5b5b
			visible: @isEdit
			parent: @

		@edit.onClick => @emit Events.Edit, @

		@height = line.height = @contentFrame().height + 20

		#
		@bind(@data) if @data

	# 데이터 바인딩
	bind: (data) ->
		@data = data
		@text.html = "<span style='font-weight:300;font-size:30px;'>#{@todayGameCount} Game</span> #{data.score} <span style='font-weight:300;font-size:30px;'>점</span>"
		@resultPhoto.setImage data.resultPhotoUrl unless _.isEmpty(data.resultPhotoUrl)

	# Event Helper
	onEdit: (cb) -> @on Events.Edit, cb